const routeMeta = {
  default: {
    title: 'Vets in Jacksonville | Sunshine Animal Clinic',
    description: 'Welcome to Sunshine Animal Clinic'
  },
  '/': {
    title: 'Vets in Jacksonville | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/about-us': {
    title: 'About Us | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services': {
    title: 'Our Services | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-wellness': {
    title: 'Wellness Plans | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-emergency': {
    title: 'Emergency Vet in Jacksonville | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-vaccination': {
    title: 'Cat & Dog Vaccination in Jacksonville | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-spay-and-neuter': {
    title: 'Cat & Dog Spaying & Neutering | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-dental': {
    title: 'Pet Dental Care | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-internal-medicine': {
    title: 'Veterinary Interal Medicine | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-heartworm': {
    title: 'Heartworm Prevention | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-deworming': {
    title: 'Dog Deworming | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-skin-care': {
    title: 'Cat & Dog Skin Care | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/services-boarding': {
    title: 'Cat & Dog Boarding | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/blogs': {
    title: 'Our Blog | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/blog-details': {
    title: 'Our Blog | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/contact-us': {
    title: 'Contact Us | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/book-an-appointment': {
    title: 'Book An Appointment | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
  '/new-client-page': {
    title: 'New Client Page | Sunshine Animal Clinic',
    description: 'Veterinary Care in Jacksonville'
  },
}

export default routeMeta;
