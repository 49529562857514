import React from "react";
import {Link} from 'react-router-dom'

function Footer() {
  return (
    <footer>
    <div className="footer-top-area footer-bg">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-3">
            <div className="footer-widget">
              <div className="f-logo">
                <Link to="/"><img src="img/logo/logo2.png" alt="" /></Link>
              </div>
              <div className="footer-text">
                <p>If you have any further questions or comments, here are the best ways to connect with us!</p>
              </div>
              <div className="footer-contact">
                <div className="icon"><i className="fas fa-phone-alt" /></div>
                <div className="content">
                  <h4 className="title"><a href="tel:0987654321">(904) 592-9166</a></h4>
                  <span>Call Us Now!</span>
                </div>
              </div>
              <div className="footer-contact">
                <div className="icon"><i className="fas fa-map-marker-alt" /></div>
                <div className="content">
                  <h4 className="title"><a href="https://g.page/sunshineanimalclinic?share" target="_blank" rel="noopener noreferrer">4131 Southside Blvd, Suite 205</a></h4>
                  <span>Jacksonville, FL 32216</span>
                </div>
              </div>
              <div className="footer-contact">
                <div className="icon"><i className="fas fa-envelope" /></div>
                <div className="content">
                  <h4 className="title"><a href="mailto: info@sunshineanimalclinic.com">Info@SunshineAnimalClinic.com</a></h4>
                  <span>Email Us!</span>
                </div>
              </div>
              <div className="footer-social">
                <ul>
                  <li><a href="https://www.facebook.com/sunshineanimalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="footer-widget">
              <h4 className="fw-title">Resources</h4>
              <div className="fw-link">
                <ul>
                  <li><Link to="/new-client-page">New client Form</Link></li>
                  <li><Link to="/book-an-appointment">Book an Appointment</Link></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://scratchpay.com/consumer/9448c3f336673f2209ae8b8a1c9c66d6/daytime-veterinary-hospital/Jacksonville-FL-32216/sunshine-animal-clinic">Scratchpay</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.carecredit.com/go/984QSJ/">CareCredit</a></li>
                  <li><Link to="/services">Our Services</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="footer-widget">
              <h4 className="fw-title">Our Services</h4>
              <div className="fw-link">
                <ul>
                  <li><Link to="/services-wellness">Physical Wellness Exam</Link></li>
                  <li><Link to="/services-emergency">Emergency & Critical Care</Link></li>
                  <li><Link to="/services-vaccination">Vaccination</Link></li>
                  <li><Link to="/services-spay-and-neuter">Spay & Neutering</Link></li>
                  <li><Link to="/services-dental">Dental</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="footer-widget">
              <h4 className="fw-title">Instagram</h4>
              <div className="fw-insta">
                <ul>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><img src="img/images/insta_img01.png" alt="" /></a></li>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><img src="img/images/insta_img02.png" alt="" /></a></li>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><img src="img/images/insta_img03.png" alt="" /></a></li>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><img src="img/images/insta_img04.png" alt="" /></a></li>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><img src="img/images/insta_img05.png" alt="" /></a></li>
                  <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><img src="img/images/insta_img06.png" alt="" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-shape"><img src="img/images/footer_shape01.png" alt="" /></div>
      <div className="footer-shape shape-two"><img src="img/images/footer_shape02.png" alt="" /></div>
    </div>
    <div className="copyright-area">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="copyright-text">
              <p>Sunshine Animal Clinic. © 2022. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

  )
}

export default Footer;
