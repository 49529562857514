import React from 'react';

import Emergency from '../components/serviceDetails/Emergency';
import EmergencyBreadCumb from '../components/serviceDetails/EmergencyBreadCumb';
import EmergencyFAQ from '../components/serviceDetails/EmergencyFAQ';
// import BlogsHome from '../components/BlogsHome';
import Newsletter from '../components/NewsLetter';

function ServicesEmergency() {
  return (
    <main>
      <EmergencyBreadCumb />
      <Emergency />
      <EmergencyFAQ />
      {/* <BlogsHome/> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Newsletter />
    </main>
  );
}

export default ServicesEmergency;
