import React from 'react';
import ServicesSidebar from './ServicesSidebar';

function Skincare() {
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title animated animatedFadeInUp fadeInUp">
                Pet Skin Care
              </h4>
              <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                Sunshine Animal Clinic
              </h6>
              <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                {' '}
              </span>
              <div className="services-detail-content">
                <p>
                  One of the more prevalent disorders affecting pets is skin
                  disease, which can be challenging to diagnose and treat.
                  Taking good care of your pet's skin is essential for
                  maintaining their health.
                </p>
                <p>
                  Simply by giving your pet a preventative treatment on a
                  regular basis, pet owners can avoid flea, tick, or lice
                  infestations. <a href="/contact-us">Contact</a> our staff to
                  inquire what your pet's best options are.
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Allergic Pet Skin Care
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  Allergy Testing & Treatment
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  We see allergic skin issues in a growing percentage of pet
                  skin care cases. Pet skin disorders can be particularly
                  difficult to manage because it is sometimes difficult to
                  determine what is causing them. Itch frequently develops into
                  secondary issues like infected ears or widespread bacterial or
                  fungal skin infections. The course of treatment is complicated
                  and frequently includes a number of drugs.
                </p>
                <p>
                  Please schedule a comprehensive{' '}
                  <a href="/book-an-appointment">appointment</a> with one of
                  our veterinarians if you suspect your pet may be experiencing
                  a skin condition. Complex skin conditions can often be
                  resolved with effort and experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skincare;
