import React from 'react';

import Skincare from '../components/serviceDetails/Skincare';
import SkincarePricing from '../components/serviceDetails/SkincarePricing'
import SkincareBreadCrumb from '../components/serviceDetails/SkincareBreadCrumb';
import SkincareFAQ from '../components/serviceDetails/SkincareFAQ';
// import BlogsHome from '../components/BlogsHome'
import Newsletter from '../components/NewsLetter';

function ServicesSkincare() {
  return (
	  <main>
		<SkincareBreadCrumb/>
		<Skincare/>
		<SkincarePricing/>
		<SkincareFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesSkincare;
