import React from 'react';
import NewClientForm from '../components/NewClientForm/NewClientForm';

function NewClientPage() {
  return (
    <main>
      <NewClientForm />
    </main>
  );
}

export default NewClientPage;
