/* global $, swal, SignaturePad */
import React, {useEffect, useState } from 'react';



function NewClientForm() {
  const [sending, setSending] = useState(false);
  var canvas;
  var signaturePad;


  useEffect(() => {
    canvas = document.querySelector('canvas#signature-pad');
    signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)'
    });
    if ($('#registration-form').length > 0) {
      function resizeCanvas() {
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
        signaturePad.clear();
      }

      // window.addEventListener("resize", resizeCanvas);
      resizeCanvas();

      $('input[name="sign_now"]').on('change', function (ev) {
        if (ev.target.checked) {
          $('.signature-pad-container').show();
          resizeCanvas();
        } else {
          $('.signature-pad-container').hide();
          resizeCanvas();
        }
      });

      $('#clear-signature').on('click', function () {
        signaturePad.clear();
        return false;
      });

      $('input[name="accepted"]').on('change', function () {
        if ($('input[name="accepted"]')[0].checked) {
          $('#registration-form .btn-submit').removeAttr('disabled');
          $('#registration-form .btn-submit').css('opacity', '1');
        } else {
          $('#registration-form .btn-submit').attr('disabled', true);
          $('#registration-form .btn-submit').css('opacity', '0.5');
        }
      });

      $(document).on('click', '.swal-button--download', function () {
        if (!$('#registration-form input[name="accepted"]')[0].checked) {
          return;
        }
        sendRegistrationForm(true);
        swal.stopLoading();
      });

      // Set proceding obligation popup
      var swalHtml = $(
        '<p style="margin-top:15px; text-align: left;"><strong>TREATMENT AUTHORIZATION and INFORMATION/PHOTO RELEASE:</strong><br />I hereby authorize Sunshine Animal Clinic, Jacksonville to perform medical Exam and initial diagnostic / surgical procedures on my pets as required for diagnosis and treatment. I understand that I can terminate treatment at any time by contacting the doctors and Staffs. I understand that some risks always exist with diagnostic procedures, medical care, anesthesia and/or surgery and that I am encouraged to discuss any concerns I have about those risks with my attending veterinarian before the procedure is initiated. Should some unexpected life-saving emergency care be required, and my attending veterinarian is unable to reach me, <strong>SUNSHINE ANIMAL CLINIC</strong> staff has my permission to provide such treatment and I agree to pay for such care. I understand that continuous 24-hour supervision of my pet is not provided. I am duly responsible to discuss with doctor about any history of any adverse reaction to drug / vaccine before introduction to my pets.</p><p style="margin-top:30px; text-align: left;"><strong>FINANCIAL POLICY (<i><span lang="EN-US">Payment is due as services are rendered.)</span></i></strong><br>For hospitalized/admitted cases, a deposit is required in advance. The balance is due upon discharge from the hospital. Payments can be made by <strong>cash</strong> and <strong>accepted credit cards</strong> (Visa, MasterCard, and American Express) including Care Credit. If payment arrangements are needed, the undersigned realizes that they must be agreed upon prior to admitting patients. In order to avoid misunderstandings, please let us know immediately if these terms are not satisfactory.</p>'
      );
      $('#registration-form #obligations').click(function () {
        swal({
          title: 'Preceding Obligations',
          className: 'reg-form-obligations',
          text: '...'
        });

        setTimeout(function () {
          $('.reg-form-obligations .swal-text').html(swalHtml);
        }, 100);

        return false;
      });
    }
  }, []);

  const submitRegistrationForm = (event) => {
    event.preventDefault();
    if (sending) return;

    if (
      !$('#registration-form input[name="first_name"]').val().trim() ||
      !$('#registration-form input[name="last_name"]').val().trim() ||
      !$('#registration-form input[name="phone"]').val().trim() ||
      !$('#registration-form input[name="email"]').val().trim() ||
      !$('#registration-form input[name="address"]').val().trim() ||
      !$('#registration-form input[name="city"]').val().trim() ||
      !$('#registration-form input[name="state"]').val().trim() ||
      !$('#registration-form input[name="zip"]').val().trim()
      // !$('#registration-form input[name="employment"]').val().trim()
      // !$('#registration-form input[name="drivers_license"]').val().trim()
    ) {
      $('#alert-msg').addClass('text-danger');
      $('#alert-msg').html('Please fill in all fields');
      $('#alert-msg').removeClass('shakeshake');
      setTimeout(() => {
        $('#alert-msg').addClass('shakeshake');
      }, 100);
      return;
    }

    if (!$('#registration-form input[name="accepted"]')[0].checked) {
      $('#alert-msg').addClass('text-danger');
      $('#alert-msg').html('Please read and accept the preceding obligations');
      $('#alert-msg').removeClass('shakeshake');
      setTimeout(() => {
        $('#alert-msg').addClass('shakeshake');
      }, 100);
      return;
    }
    sendRegistrationForm(false);
  };

  const sendRegistrationForm = (print) => {
    var data = {
      first_name: $('#registration-form input[name="first_name"]').val(),
      last_name: $('#registration-form input[name="last_name"]').val(),
      spouse_name: $('#registration-form input[name="spouse_name"]').val(),
      phone: $('#registration-form input[name="phone"]').val(),
      phone_alt: $('#registration-form input[name="phone_alt"]').val(),
      email: $('#registration-form input[name="email"]').val(),
      address: $('#registration-form input[name="address"]').val(),
      city: $('#registration-form input[name="city"]').val(),
      state: $('#registration-form input[name="state"]').val(),
      zip: $('#registration-form input[name="zip"]').val(),
      // employment: $('#registration-form input[name="employment"]').val(),
      best_time_to_reach: $(
        '#registration-form input[name="best_time_to_reach"]'
      ).val(),
      // drivers_license: $(
      //   '#registration-form input[name="drivers_license"]'
      // ).val(),
      // other_persons: $('#registration-form input[name="other_persons"]').val(),

      pet_1_name: $('#registration-form input[name="pet_1_name"]').val(),
      pet_1_breed: $('#registration-form input[name="pet_1_breed"]').val(),
      pet_1_age: $('#registration-form input[name="pet_1_age"]').val(),
      pet_1_clinic: $('#registration-form input[name="pet_1_clinic"]').val(),
      pet_2_name: $('#registration-form input[name="pet_2_name"]').val(),
      pet_2_breed: $('#registration-form input[name="pet_2_breed"]').val(),
      pet_2_age: $('#registration-form input[name="pet_2_age"]').val(),
      pet_2__clinic: $('#registration-form input[name="pet_2__clinic"]').val(),

      how_did_you_hear: $(
        '#registration-form textarea[name="how_did_you_hear"]'
      ).val(),
      accepted: $('#registration-form input[name="accepted"]')[0].checked,
      sign_now: $('#registration-form input[name="sign_now"]')[0].checked,
      signature: $('#registration-form input[name="accepted"]')[0].checked
        ? $('#signature-pad')[0].toDataURL('image/jpeg', 0.1)
        : ''
    };

    var form = document.createElement('form');
    var formName = 'regform-' + new Date().getTime();

    Object.keys(data).forEach(function (key) {
      var field = document.createElement('input');
      field.setAttribute('type', 'hidden');
      field.setAttribute('name', key);
      field.setAttribute('value', data[key]);
      form.appendChild(field);
    });
    document.body.appendChild(form);

    $('#registration-form .btn-submit').attr('disabled', true);
    $('#registration-form .btn-submit').css('cursor', 'not-allowed');
    $('#registration-form .btn-submit').css('opacity', '0.5');
    $('#registration-form .alert-msg').html('');

    if (!print) {
      setSending(true);
      $.ajax({
        type: 'POST',
        url: 'https://api.sunshineanimalclinic.com/pdf/register.php',
        data: $(form).serialize(),
        success: function (data) {
          console.log(data);
          try {
            var res = JSON.parse(data);
            if (res.message === 'OK') {
              signaturePad.off();
              var form = document.getElementById('registration-form');
              var elements = form.elements;
              for (var i = 0, len = elements.length; i < len; ++i) {
                $(elements[i]).attr('disabled', 'true');
              }
              $('#registration-form .btn-submit').hide();
              $('#alert-msg').removeClass('text-danger');
              $('#alert-msg').addClass('text-success');
              $('#alert-msg').html('Registration successful!');
              swal({
                title: 'Thank you for registering',
                className: 'reg-form-success',
                text: 'Thank you for registering. You may download and print the form using the button below.',
                buttons: {
                  cancel: 'Close',
                  download: {
                    text: 'Download Form',
                    value: 'download',
                    closeModal: false
                  }
                }
              }).then(function (value) {
                switch (value) {
                  case 'download':
                    sendRegistrationForm(true);
                    break;
                  default:
                }
                swal.stopLoading();
              });
            }
          } catch (err) {
            console.dir('Error sending email', err);
            $('#registration-form .alert-msg').html(
              'There was an error submitting the form'
            );
          }
          $('#registration-form .btn-submit').removeAttr('disabled');
          $('#registration-form .btn-submit').css('cursor', 'pointer');
          $('#registration-form .btn-submit').css('opacity', '1');
        },
        error: function (xhr, textStatus, errorThrown) {
          console.dir(xhr, textStatus, errorThrown);
          $('#registration-form .btn-submit').removeAttr('disabled');
          $('#registration-form .btn-submit').css('cursor', 'pointer');
        },
        complete: function() {
          setSending(false);
        }
      });
    } else {
      data.print = true;
      form.setAttribute('method', 'post');
      form.setAttribute(
        'action',
        'https://api.sunshineanimalclinic.com/pdf/register.php?print=true'
      );
      form.setAttribute('target', formName);
      window.open('', formName);

      form.submit();
      document.body.removeChild(form);
    }
  };
  
    
  /* - New Client Form */
  return (

    
    <section className="contact-area pt-110 pb-110 px-4">
      <div className="container">
          <div className="row justify-content-center">
            <div>
              <div className="contact-title mb-20 text-center">
                <h5 className="sub-title">Sunshine Animal Clinic</h5>
                <h2 className="title">
                  New Client Registration Form<span>.</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <div className="text-center">
                  <p>
                    We are so happy that you have chosen Sunshine Animal Clinic
                    to take care of your pets' health care needs. <br></br>{' '}
                    Please fill out the following information. <br></br> This
                    lets us record your information into our software so that we
                    can effectively and efficiently provide the service you
                    deserve!
                  </p>
                </div>
                <form className="contact-form" id="registration-form">
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="first_name">
                          First Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          placeholder="First Name"
                          name="first_name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="last_name">
                          Last Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="phone">
                          Phone Number <span>*</span>
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="email">
                          Email <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="address">
                          Address <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Address"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="city">
                          City <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          placeholder="City"
                          name="city"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="state">
                          State <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          placeholder="State"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="zip">
                          Zip <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="zip"
                          name="zip"
                          placeholder="Zip"
                          required
                        />
                      </div>
                    </div>
                  </div> 
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="spouse_name">
                          Spouse/Co-Owner's Name{' '}
                        </label>
                        <input
                          type="text"
                          id="spouse_name"
                          name="spouse_name"
                          placeholder="Spouse Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="phone_alt">
                          {' '}
                          Spouse/Co-Owner's Phone Number
                        </label>
                        <input
                          type="tel"
                          id="phone_alt"
                          name="phone_alt"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-grp">
                        <label htmlFor="best_time_to_reach">
                          Best time to reach you
                        </label>
                        <input
                          id="best_time_to_reach"
                          type="text"
                          name="best_time_to_reach"
                          placeholder="10 AM"
                        />
                      </div>
                    </div>
                  </div>               
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_1_name">
                          Pet 1 Name 
                        </label>
                        <input
                          type="text"
                          id="pet_1_name"
                          placeholder="Pet Name"
                          name="pet_1_name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_1_breed">
                          Pet 1 Breed
                        </label>
                        <input
                          type="text"
                          id="pet_1_breed"
                          name="pet_1_breed"
                          placeholder="Breed"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_1_age">
                          Pet 1 Age / Birthday
                        </label>
                        <input
                          type="text"
                          id="pet_1_age"
                          name="pet_1_age"    
                          placeholder="Age / Birthday"                
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_1_clinic">
                        Previous Animal Clinic Name
                        </label>
                        <input
                          type="text"
                          id="pet_1_clinic"
                          name="pet_1_clinic"
                          placeholder="Previous Clinic Name"                      
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_2_name">
                          Pet 2 Name 
                        </label>
                        <input
                          type="text"
                          id="pet_2_name"
                          placeholder="Pet Name"
                          name="pet_2_name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_2_breed">
                          Pet 2 Breed
                        </label>
                        <input
                          type="text"
                          id="pet_2_breed"
                          name="pet_2_breed"
                          placeholder="Breed"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_2_age">
                          Pet 2 Age / Birthday
                        </label>
                        <input
                          type="text"
                          id="pet_2_age"
                          name="pet_2_age"    
                          placeholder="Age / Birthday"                
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-grp">
                        <label htmlFor="pet_2__clinic">
                        Previous Animal Clinic Name
                        </label>
                        <input
                          type="text"
                          id="pet_2__clinic"
                          name="pet_2__clinic"
                          placeholder="Previous Clinic Name"                      
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <label htmlFor="how_did_you_hear">
                      How Did you Hear About us?
                    </label>
                    <textarea
                      id="textarea_message"
                      name="how_did_you_hear"
                      placeholder="Google, Social Media..."
                      defaultValue={''}
                      required
                    />
                  </div>
                  <div className="form-grp checkbox-grp">
                    <input
                      type="checkbox"
                      name="accepted"
                      id="accepted-checkbox"
                    />
                    <label htmlFor="accepted-checkbox">
                      I have read and accept the
                      <a href="=#/" id="obligations">
                        {' '}
                        preceding obligations.
                      </a>
                    </label>
                  </div>
                  <div className="form-grp checkbox-grp">
                    <input type="checkbox" id="sign-checkbox" name="sign_now" />
                    <label htmlFor="sign-checkbox">
                      I would like to sign now.
                    </label>
                  </div>
                  <div
                    className="col-xs-12 signature-pad-container text-center"
                    style={{ display: 'none' }}
                  >
                    <br />
                    <div
                      className="text-center"
                      style={{ display: 'inline-block' }}
                    >
                      <strong>Please sign below</strong>
                      <br />
                      <canvas
                        id="signature-pad"
                        style={{
                          border: '1px solid black',
                          height: '200px',
                          width: '700px',
                          maxWidth: '100%'
                        }}
                      ></canvas>
                      <br />
                      <div className="text-right">
                        <a
                          href="#/"
                          id="clear-signature"
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                          }}
                        >
                          Clear
                        </a>
                      </div>
                    </div>
                    <input type="hidden" name="signature" />
                  </div>

                  <div id="alert-msg" className="mb-3"></div>
                  <button
                    type="button"
                    className="btn btn-submit rounded-btn"
                    disabled={sending}
                    onClick={submitRegistrationForm}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
      </div>
    </section>
  );
}

export default NewClientForm;
