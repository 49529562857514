import React from 'react';
import ServicesSidebar from './ServicesSidebar';
import Accordion from 'react-bootstrap/Accordion';

function Emergency() {
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title animated animatedFadeInUp fadeInUp">
                What to Do In A Pet Emergency
              </h4>
              <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                Sunshine Animal Clinic
              </h6>

              <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                {' '}
              </span>
              <div className="services-detail-content">
                <p>
                  If you are concerned about your pet’s unusual behavior or any
                  abnormal behavior they may be displaying, please contact
                  Sunshine Animal Clinic immediately. If the emergency happens
                  to be outside of our clinic hours, contact the nearest
                  emergency location to you.
                </p>
                <p>
                  If you would like more information on what to do during an
                  emergency, don’t hesitate to{' '}
                  <a href="tel:0987654321">Call us!</a>
                </p>
                <span>
                  We're here to answer any questions and provide you with all
                  the information you need to keep your furry family member safe
                  and healthy!
                </span>
                <br></br>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Steps to Take If Your Pet Needs Urgent Emergency Care
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  What you need to know
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  If you are experiencing a veterinary emergency during our
                  normal office hours, follow the steps below:
                </p>
                <p></p>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      Stay Calm and Assess the Problem
                    </Accordion.Header>
                    <Accordion.Body>
                      The most crucial thing to keep in mind when handling a pet
                      emergency is to simply maintain your composure. Your pet
                      will become more fearful if you grow anxious. Furthermore,
                      anxiety makes it more difficult for you to focus on
                      important information that could endanger your pet's life.
                      <br></br>
                      <br></br>
                      Additionally, try to identify the issue by giving your pet
                      a close look. You'll likely be able to see this quite
                      clearly if she has a severely shattered bone or other
                      serious injuries. In contrast, if your pet is extremely
                      ill but you don't know why, you should pay great attention
                      to her symptoms and behavior so you can explain everything
                      to the doctor when you get there.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {' '}
                      Call Our Clinic Ahead of Time If Possible
                    </Accordion.Header>
                    <Accordion.Body>
                      <a href="tel:0987654321">Call us</a> as soon as possible
                      after evaluating your pet and providing the best care you
                      can at home. If you're not sure whether it's an emergency
                      or not, <a href="/contact-us">contact us</a> and consult
                      to one of our veterinarians to find out how critical the
                      situation is. Do not wait until it is too late to call the
                      veterinarian.
                      <br></br>
                      <br></br>
                      You must locate an emergency veterinarian if it is after
                      business hours, on the weekend, or on a legal holiday.
                      Ideally, you should already be aware of a local emergency
                      veterinarian. If you aren't, you can quickly search the
                      internet for the one that is closest to you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {' '}
                      Ask A Family Member for Help
                    </Accordion.Header>
                    <Accordion.Body>
                      You're going to most likely require assistance with your
                      pet. You might have to handle everything on your own if
                      there is no one else at home or nearby who can assist you
                      in some pet crises. But if you can, enlist the aid of a
                      relative in caring for your pet.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {' '}
                      Drive Safely to The Vet
                    </Accordion.Header>
                    <Accordion.Body>
                      It's time to start driving after notifying the
                      veterinarian and safely putting your pet in the car.
                      <br></br>
                      <br></br>
                      Drive more cautiously and pay attention to the road. If at
                      all possible, let your assistant drive the vehicle while
                      you provide your pet as much comfort as you can.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Emergency;
