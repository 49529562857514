import React from 'react';

function DewormingFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageDeworming.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Pet Deworming</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Why is my Pet at a Higher Risk if they have Fleas?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      Because fleas itch, animals with fleas may mistakenly consume them while being groomed. Flea larvae can feed on dipylidium caninum tapeworm eggs present in the environment and grow inside the flea's abdomen. If a dog or cat eats an infected flea, the tapeworm also gets inside of them. The tapeworm's scolex attaches to the host's intestinal mucosa and develops into an adult tapeworm. Rarely, if fleas are consumed, this can also happen to people, especially kids. This is why having effective flea and worm management is crucial.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Why is Deworming Important in Puppies and Kittens?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      Roundworms, or toxocara, can be found in puppies as young as two weeks old and in kittens as young as six weeks old. Almost all young animals have the Toxocara infection, which can lead to significant illness. If the worms from Toxocara are consumed, people can potentially contract illness. Given that it can result in blindness, this is particularly crucial for kids.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What are some common signs of worms?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                      <p>Some types of worms will not show any signs of infestation until in the later stages but some common signs to look out for are:</p>
                      <p className="tab"><i className="flaticon-tick"/> Anemia (pale skin and gums)</p>
                      <p className="tab"><i className="flaticon-tick"/> Vomiting</p>
                      <p className="tab"><i className="flaticon-tick"/> Weight loss</p>
                      <p className="tab"><i className="flaticon-tick"/> Pot-belly (mostly in pups)</p>
                      <p className="tab"><i className="flaticon-tick"/> Bloody stool</p>
                      <p className="tab"><i className="flaticon-tick"/> Diarrhea</p>
                      <p className="tab"><i className="flaticon-tick"/> Dry/dull coat</p>
                      <p className="tab"><i className="flaticon-tick"/> General poor appearance</p>
                      <p className="tab"><i className="flaticon-tick"/> Weakness</p>
                      <p className="tab"><i className="flaticon-tick"/> Tail skidding (dog rubs butt on the carpet)</p>
                      <p className="tab"><i className="flaticon-tick"/> Visible signs of worms or worm segments in stool, vomit, or around the anus dried in the fur</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        How can I prevent Intestinal Worms?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                      Again, the most important for treating intestinal worms is preventing it. You can take simple steps to preventing your puppy or dog from contracting an intestinal parasite.
                      <p></p>
                      <p className="tab"><i className="flaticon-tick"/> Stick to routine vaccinations, deworming, and vet exams at a vet hospital</p>
                      <p className="tab"><i className="flaticon-tick"/> Keep any area where the puppy or dog will be kept clean, such as outdoor kennels and sleeping areas</p>
                      <p className="tab"><i className="flaticon-tick"/> Use a flea and tick prevention</p>
                      <p className="tab"><i className="flaticon-tick"/> Remove feces from your yard regularly</p>
                      <p className="tab"><i className="flaticon-tick"/> Don’t feed raw meat or offal; base your dog’s staple diet on a high-quality dog food</p>
                      <p>Check out the variety of Pet Hospital Medical Care that we offer. <a href="/contact-us">Contact</a> our Sunshine Animal Clinic to speak to the professional animal care staff about deworming.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default DewormingFAQ;
