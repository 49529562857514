/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'
import PricingCard from './PricingCard'

function SpayNeuterPricing() {

  return (
    <section className="pricing-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Price List</h5>
                <h2 className="title">Spaying & Neutering a Dog</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Spaying - Dog" price="275" label1="Starting at" label2="Canine Spay up to 50 lbs" availability="Monday - Saturday"/>
            <PricingCard title="Spaying - Dog" price="320" label1="Starting at" label2="Canine Spay more than 50 lbs" availability="Monday - Saturday"/>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="NEUTERING  - Dog" price="145" label1="Starting at" label2="Canine Spay up to 50 lbs" availability="Monday - Saturday"/>
            <PricingCard title="NEUTERING  - Dog" price="175" label1="Starting at" label2="Canine Spay more than 50 lbs" availability="Monday - Saturday"/>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <h2 className="title">Spaying & Neutering a Cat</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Spaying - Cat" price="145" label1="Starting at" availability="Monday - Saturday"/>
            <PricingCard title="NEUTERING  - Cat" price="80" label1="Starting at" availability="Monday - Saturday"/>
          </div>
        </div>
    </section>
  )
}

export default SpayNeuterPricing;
