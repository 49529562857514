import React from 'react';

function SkincareFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageSkincare.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Pet Skin Care</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How can I tell if my pet has a skin problem?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      The most common signs of a dermatological condition in pets include excessive head shaking and head biting, licking, scratching, and chewing of skin and fur. In addition to these signs, your pet may also experience hair loss, dandruff, lumps, or a change in the color or texture of their skin or fur.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How does a veterinarian diagnose a skin problem?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      Our veterinarian will start by checking your pet for allergies if the skin issue affects the entire body. In cases where the symptoms seem to be localized, our veterinarian will check for parasites including fleas, ticks, lice, or ringworm. Blood testing may be needed to pinpoint the exact cause if the issue appears to be linked to an underlying condition.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What causes skin problems in pets?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                      <p>Pets' skin issues can be brought on by a number of things. This is why it may be necessary to rule out some possibilities in order to diagnose a dermatological problem. All causes can be divided into six groups:</p>
                      <p className="tab"><i className="flaticon-tick"/> Allergies - sensitivity to medication, food, pollen, dust, bacteria, etc.</p>
                      <p className="tab"><i className="flaticon-tick"/> Environmental - sensitivity to the environment like grass, bacteria in water, or thistles</p>
                      <p className="tab"><i className="flaticon-tick"/> Nutritional - lack of proper nutrition</p>
                      <p className="tab"><i className="flaticon-tick"/> Parasitic - commonly fleas, ticks, and mites</p>
                      <p className="tab"><i className="flaticon-tick"/> Infectious - bacterial, fungal, and yeast</p>
                      <p className="tab"><i className="flaticon-tick"/> Neurogenic - simply neurological, a tic rather than a tick</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Are skin problems treatable?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                      <p>Yes. Depending on the underlying cause of your pet's dermatological issue, our veterinarian may recommend oral medications, hypoallergenic food diet modifications, topical cream treatments, environmental changes, dietary adjustments, special baths, or a thorough cleaning of your home to get rid of any parasites.</p>
                      <p><a href="/book-an-appointment">Schedule an appointment</a> at Sunshine Animal Clinic right away if your pet exhibits signs of a skin condition.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default SkincareFAQ;
