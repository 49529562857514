/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'

function ServicesPromotion() {

  useEffect(() => {
    if (window.location.hash === '#promotions') {
      $('html, body').animate({
        scrollTop: $("#services-promotion").offset().top - ($('#sticky-header').height() / 4)
      }, 500);
    }
  }, []);

  return (
    <section className="adoption-shop-area" id="services-promotion">
      <div className="container" >
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
              <h5 className="sub-title">Promotions Currently Available</h5>
              <h2 className="title">A special kind of animal hospital</h2>
              <p>At Sunshine Animal Clinic, we are happy to offer special discounts for new and existing clients.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/services/PromotionNewClient.jpg" alt="" />
                <Link to="/contact-us" className="btn">Visit Us <img src="img/icon/w_pawprint.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h5 className="title"><Link to="/contact-us">New Client Discount</Link></h5>
                <div className="adoption-meta">
                  <ul>
                  <li><span>Save 50% on First Office Visit / Exam Fee</span></li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Includes an Office and Wellness Examination</li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Subsequent visit is $45</li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Recheck visit is $35 within 3 months</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="price">Total Discount : <span>50% ($22.50)</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/services/PromotionVaccineDog.jpg" alt="" />
                <Link to="/contact-us" className="btn">Visit Us<img src="img/icon/w_pawprint.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title"><Link to="/contact-us">Vaccination Discount for Adult Dog</Link></h4>
                <div className="adoption-meta">
                  <ul>
                  <li><i className="flaticon-tick" />Puppy litter package discount available</li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Puppy litter package discount available Package Includes Comprehensive Exam, DHPP, Kennel Cough, 1 year Rabies, Heartworm Test and Fecal Flotation Test</li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Does not include City license Fee</li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="price">Discounted Price : <span>$115</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/services/PromotionVaccineCat.jpg" alt="" />
                <Link to="/contact-us" className="btn">Visit Us<img src="img/icon/w_pawprint.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title"><Link to="/contact-us">Vaccination Discount for Adult Cat</Link></h4>
                <div className="adoption-meta">
                  <ul>
                  <li><i className="flaticon-tick" />Cat litter package discount available</li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Package Includes Comprehensive Exam, FVRCP, 1 year Rabies and Fecal Flotation test</li>
                  <li> <br></br></li>
                  <li><i className="flaticon-tick" />Does not include City license Fee</li>
                  <li> <br></br></li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="price">Discounted Price : <span>$95</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/services/PromotionSenior.jpg" alt="" />
                <Link to="/contact-us" className="btn">Visit Us<img src="img/icon/w_pawprint.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title"><Link to="/contact-us">Senior Discount</Link></h4>
                <div className="adoption-meta">
                  <ul>
                  <li><span>All Seniors, Ages 65 and above, are eligible to receive 3% off our medical services and boarding.</span></li>
                  <li> <br></br></li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="price">Total Discount : <span>3%</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/services/PromotionMilitary.jpg" alt="" />
                <Link to="/contact-us" className="btn">Visit Us<img src="img/icon/w_pawprint.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title"><Link to="/contact-us">Military Discount</Link></h4>
                <div className="adoption-meta">
                  <ul>
                  <li><span>All active-duty military personnel are eligible to receive 3% off our medical services and boarding.</span></li>
                  <li> <br></br></li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="price">Total Discount : <span>3%</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="img/services/PromotionReferral.jpg" alt="" />
                <Link to="/contact-us" className="btn">Visit Us <img src="img/icon/w_pawprint.png" alt="" /></Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title"><Link to="/contact-us">Referral Rewards Program</Link></h4>
                <div className="adoption-meta">
                  <ul>
                  <li><span>Refer Sunshine Animal Clinic to your friends and we will reward you both!</span></li>
                  <li> <br></br></li>
                  <li><span>Your Reward:</span> Receive a $10.00 Credit on your Pets medical service and boarding</li>
                  <li> <br></br></li>
                  <li><span>Your Friend's Reward:</span> Receive $5.00 off his/her first office visit on top of the New Client Discount</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesPromotion;
