import React from 'react';
import ServicesSidebar from './ServicesSidebar';
import Accordion from 'react-bootstrap/Accordion';

function Dental() {
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title animated animatedFadeInUp fadeInUp">
                Dental Health
              </h4>
              <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                Sunshine Animal Clinic
              </h6>
              <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                {' '}
              </span>
              <div className="services-detail-content">
                <p>
                  Regular dental care is important for the oral and general
                  health of your cat or dog. Unfortunately, many animals don't
                  receive the dental care they require to keep their teeth and
                  gums healthy.
                </p>
                <p>
                  Dental disease has the ability to enter your pet's bloodstream
                  and can cause problems with the heart, liver, and kidneys. In
                  actuality, the most prevalent pet health issue among our pet
                  population is dental disease, which affects 80% of dogs and
                  70% of cats over the age of three.
                </p>
                <p>
                  At Sunshine Animal Clinic, we provide comprehensive dental
                  care, ranging from routine checkups, teeth cleanings, and
                  polishings to dental operations to fix significant damage.
                </p>
                <p>
                  Additionally, we ensure that owners have the information they
                  require to maintain their pet's dental health in between
                  exams.
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Does Your Pet Have Bad Breath?
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  What you need to know
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  Unfortunately, most pets do, and this is not normal.
                  Their mouth infection is the source of the unpleasant odor you might
                  smell. Over 75% of pets older than two years old have
                  periodontal disease, which is the most frequent source of
                  infection in your pet's mouth. Periodontal disease, which is
                  brought on by ongoing inflammation and infection in the mouth,
                  progresses and causes irreversible loss of the supporting
                  tissues for the teeth. When your pet eats, leftover food
                  particles in the mouth encourage bacterial development.
                  Plaque, a slimy film created by the bacteria, sticks to the
                  teeth and hardens into calculus and tartar.
                </p>
                <p>
                  Spaying a female cat before her first heat cycle practically
                  eliminates the risk of mammary (breast) cancer. Furthermore,
                  spayed female cats also cannot develop ovarian and uterine
                  cancers. Spaying also helps reduce the possibility of
                  complications that can occur during pregnancy and birthing.
                </p>
                <p>
                  To avoid discomfort, tooth loss, and expensive treatments, it
                  is best to seek treatment early. If periodontal disease is not
                  treated, it could result in:
                </p>
                <p className="tab">
                  <i className="flaticon-tick" />{' '}
                  <strong>Decreased quality of life</strong>
                </p>
                <p className="tab">
                  <i className="flaticon-tick" />{' '}
                  <strong>Decreased appetite and weight loss</strong>
                </p>
                <p className="tab">
                  <i className="flaticon-tick" />{' '}
                  <strong>
                    Tooth loss due to loss of supporting tissues around teeth
                  </strong>
                </p>
                <p className="tab">
                  <i className="flaticon-tick" />{' '}
                  <strong>Chronic pain from infection and inflammation</strong>
                </p>
                <p className="tab">
                  <i className="flaticon-tick" />{' '}
                  <strong>
                    Distant organ (e.g.: liver, kidneys, heart valves) damage
                    from bacteria showering from the mouth to the bloodstream
                  </strong>
                </p>
                <p className="tab">
                  <i className="flaticon-tick" />{' '}
                  <strong>Adverse behaviors caused by pain</strong>
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Pet Teeth Cleaning & Exams
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  Our Procedure
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  Similar to your yearly dental checkup, your dog or cat should
                  have a dental cleaning at least once every 12 months. We may
                  need to examine your pet more frequently if they are more
                  susceptible to dental issues than other pets.
                </p>
                <p>
                  Veterinarians at Sunshine Animal Clinic are able to evaluate,
                  identify, and treat canine and feline dental health issues.
                </p>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      How Do I Know My Pet Needs a Dental Checkup?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        It's time for a dental examination if your pet exhibits
                        any of the signs below.
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Discolored teeth{' '}
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Loose and/or broken
                        teeth
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Extra teeth or retained
                        baby teeth
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Bleeding from the mouth
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Bad breath
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Pain or swelling in or
                        around the mouth
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Reduced appetite or
                        refusal to eat
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Abnormal chewing,
                        drooling, or dropping food from the mouth{' '}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {' '}
                      Pre-Dental Cleaning / Exam Assessment
                    </Accordion.Header>
                    <Accordion.Body>
                      Before the dental exam, your pet will have a full
                      pre-anesthetic physical examination done if necessary.
                      <br></br> <br></br>
                      To make sure it is safe for your pet to receive
                      anesthesia, we will collect blood and urine samples for
                      analysis. We will perform a thorough oral examination
                      (tooth by tooth) and charting after your pet is asleep.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header> Dental Cleaning / Exam</Accordion.Header>
                    <Accordion.Body>
                      In addition to taking X-rays, the teeth are cleaned and
                      polished from both under and above the gum line. Then we give each tooth
                      a fluoride treatment.
                      <br></br>
                      <br></br>
                      Applying a dental sealant as the last step will stop
                      plaque from adhering to the enamel. Our veterinarians at
                      Sunshine Animal Clinic will come up with a treatment
                      strategy and go over it with you if advanced periodontal
                      disease is discovered.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {' '}
                      Post Dental Cleaning / Exam
                    </Accordion.Header>
                    <Accordion.Body>
                      The ideal time to schedule a follow-up exam is two weeks
                      after the initial pre dental assessment and dental
                      cleaning visit.
                      <br></br>
                      <br></br>
                      During this appointment, our veterinarians will talk about
                      establishing home tooth brushing. We can also make product
                      recommendations to help your pet's oral health.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">Dental Packages</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Know your options</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> Dental Package 1</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> Cleaning & Polishing with no extractions <strong>excluding</strong> Dental X-ray</p>
                            <i>*Blood-work / medicines <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header> Dental Package 2</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> Dental Cleaning, Polishing up to 3 teeth extractions <strong>excluding</strong> Dental X-ray</p>
                            <i>*Blood-work / medicines <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                     <Accordion.Item eventKey="2">
                        <Accordion.Header> Dental Package 3</Accordion.Header>
                          <Accordion.Body>
                          <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                          <p className="tab"><i className="flaticon-tick"/> Dental Cleaning, Polishing over 3 teeth extractions <strong>excluding</strong> Dental X-ray</p>
                          <i>*Blood-work / medicines <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dental;
