import React from 'react';

function EmergencyFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageEmergency.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs </h5>
                  <h2 className="title">Emergency & Critical Care</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        When Is Your Clinic Open?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      <p>We see emergency cases during our regular office hours:</p>
                      <p><strong>Monday to Friday:</strong> 9:00am - 7:00pm</p>
                      <p><strong>Saturday:</strong> 10:00am - 3:00pm</p>
                      <p><strong>Sunday:</strong> Closed</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What Situations Classify as an Emergency Veterinary Care?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      <p>Emergency scenarios that call for prompt attention include the following:</p>

                      <p className="tab"><i className="flaticon-tick"/> Choking, difficulty breathing or nonstop coughing and gagging</p>
                      <p className="tab"><i className="flaticon-tick"/> Bleeding from nose, mouth, rectum, coughing up blood, or blood in urine</p>
                      <p className="tab"><i className="flaticon-tick"/> Injuries to your pet's eye(s)</p>
                      <p className="tab"><i className="flaticon-tick"/> Fractured bones, severe lameness or inability to move leg(s)</p>
                      <p className="tab"><i className="flaticon-tick"/> Heat stress or heatstroke</p>
                      <p className="tab"><i className="flaticon-tick"/> Severe vomiting or diarrhea – more than two episodes in a 24-hour period</p>
                      <p className="tab"><i className="flaticon-tick"/> Refusal to drink for 24 hours or more</p>
                      <p>The bottom line is that <strong>ANY worry you have about your pet's health justifies calling your veterinarian.</strong></p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How Long will We Have to Wait Before Seeing The Vet?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>The wait times are Sunshine Animal Clinic can be unpredictable and you may have to wait. While we cannot predict what cases we will see and when they will arrive, we try to prioritize serious emergency cases first. Please <a href="tel:0987654321">call us</a> advance to find out whether we are able to provide emergency care for your dog or cat.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          What happens if my Pet needs to stay in the hosiptal overnight?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                        Some veterinary emergencies demand long-term care. At Sunshine Animal Care, we are able to provide overnight care for non-critical patients.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default EmergencyFAQ;
