import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import $ from 'jquery';
import useWindowSize from '../utilities/windowSize';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import metaDescription from '../utilities/metaDescription';


function Header(){
  const [windowWidth] = useWindowSize();
  const location = useLocation();

  const renderClientFormTooltip = props => (
    <Tooltip {...props}>New Client Form</Tooltip>
  );
  const renderAppointmentTooltip = props => (
    <Tooltip {...props}>Get An Appointment</Tooltip>
  );

  const handleServicesActive = () => {
    $('a[href="/services"]').parent().addClass('active');
    document.querySelectorAll('.main-menu .submenu li').forEach(el => {
      el.classList.remove('active');
    });
    document.querySelectorAll('.main-menu .submenu li a').forEach(a => {
      if (a.href.replace(window.location.origin, '') === location.pathname) {
        a.parentNode.className += ' active';
      }
    });
  };

  const handleActive = (e)=>{
    document.querySelectorAll('.main-menu ul li').forEach( el => {
      el.classList.remove('active');
    })
    e.target.parentNode.classList += ' active';
  }

  const subActive = (e)=>{
    document.querySelectorAll('.main-menu ul li').forEach( el => {
      el.classList.remove('active');
    })
    e.target.parentNode.parentNode.parentNode.classList += ' active';
  }

  // ROUTING EFFECT
  useEffect(() => {
    // Update meta description
    const meta = metaDescription[window.location.pathname];
    const metaDefault = metaDescription.default;
    document.title = meta ? meta.title : metaDefault.title;
    document.description = meta ? meta.description : metaDefault.description;

    // GOOGLE ANALYTICS
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search
    });
    $('ul.navigation li').removeClass('active');
    $('ul.navigation li a').each((i, el) => {
      if (el.getAttribute('href').replace(window.location.origin, '') === location.pathname) {
        $(el).parent().addClass('active');
      }
    });
    if (location.pathname.includes('/services')) {
      handleServicesActive();
    }
  }, [location]);

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($('.menu-area li.menu-item-has-children ul').length) {
      $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

    }
    if ($('.mobile-menu').length) {
      var mobileMenuContent = $('.menu-area .main-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
        $(this).toggleClass('open');
        $(this).prev('ul').slideToggle(500);
      });
      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      //Menu Toggle Btn
      $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }

   $(".navbar-toggle").on('click', function () {
        $(".navbar-nav").addClass("mobile_menu");
      });
      $(".navbar-nav li a").on('click', function () {
        $(".navbar-collapse").removeClass("show");
      });

        $(".header-search > a").on('click', function () {
        $(".search-popup-wrap").slideToggle();
          return false;
        });

        $(".search-close").on('click',function () {
        $(".search-popup-wrap").slideUp(500);
        });
  },[])



    return(
      <header>
        <div className="header-top-area">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-7 col-6">
                <div className="header-top-left">
                  <ul>
                    <li><i className="fas fa-phone-alt" /><a href="tel:0987654321"><span>(904) 592-9166</span></a></li>
                    <li><i className="fas fa-map-marker-alt" /><a href="https://g.page/sunshineanimalclinic?share" target="_blank" rel="noopener noreferrer"><span>Our Clinic</span></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 col-6">
                <div className="header-top-right">
                  <ul className="header-top-social">
                    <li className="follow">Follow Us : </li>
                    <li><a href="https://www.facebook.com/sunshineanimalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sticky-header" className="menu-area">
          <div className={windowWidth > 1500 ? 'container custom-container' : 'container-fluid'}>
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
                <div className="menu-wrap">
                  <nav className="menu-nav show">
                    <div className="logo">
                      <Link to="/">
                        <img src="img/logo/logo.png" alt="" style={{
                          maxWidth: windowWidth < 1080 ? '200px' : '100%'
                        }} />
                      </Link>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className={`navigation ${ windowWidth < 1150 && windowWidth > 1080 ? 'ml-3' : ''}`}>

                        <li><Link to="/" onClick={(e)=> handleActive(e)}>Home</Link></li>

                        <li><Link to="/about-us" onClick={(e)=> handleActive(e)}>About Us</Link></li>

                        <li className="menu-item-has-children"><Link to="/services" onClick={(e)=> handleActive(e)} >Services</Link>
                          <ul className="submenu">
                            <li><Link to="/services-wellness" onClick={(e)=> handleActive(e)}>Physical Wellness Exams</Link></li>
                            <li><Link to="/services-emergency" onClick={(e)=> handleActive(e)}>Emergency & Critical Care</Link></li>
                            <li><Link to="/services-vaccination" onClick={(e)=> handleActive(e)}>Vaccination</Link></li>
                            <li><Link to="/services-spay-and-neuter" onClick={(e)=> handleActive(e)}>Spay / Neuter Surgery</Link></li>
                            <li><Link to="/services-dental" onClick={(e)=> handleActive(e)}>Dental</Link></li>
                            <li><Link to="/services-internal-medicine" onClick={(e)=> handleActive(e)}>Internal Medicine</Link></li>
                            <li><Link to="/services-heartworm" onClick={(e)=> handleActive(e)}>Heartworm Prevention</Link></li>
                            <li><Link to="/services-deworming" onClick={(e)=> handleActive(e)}>Pet Deworming</Link></li>
                            <li><Link to="/services-skin-care" onClick={(e)=> handleActive(e)}>Pet Skin care</Link></li>
                            <li><Link to="/services-boarding" onClick={(e)=> handleActive(e)}>Pet Boarding</Link></li>
                          </ul>
                        </li>

                        <li> <a href="https://sunshineanimalclinic.securevetsource.com/index.pml" target="_blank" rel="noopener noreferrer">shop</a></li>




                        {/* <li className="menu-item-has-children"><Link to="/blogs" onClick={(e)=> handleActive(e)}>Blog</Link>
                          <ul className="submenu">
                            <li><Link to="/blog-details" onClick={(e)=> subActive(e)}>Blog Details</Link></li>
                          </ul>
                        </li> */}

                        <li><Link to="/contact-us" onClick={(e)=> handleActive(e)}>contact Us</Link></li>

                        <li className="menu-item-has-children">
                          <Link to="#/" onClick={(e)=> handleActive(e)}>Resources</Link>
                          <ul className="submenu">
                          <li><Link to="/book-an-appointment" onClick={(e)=> subActive(e)}>Book an Appointment</Link></li>
                          <li><Link to="/new-client-page" onClick={(e)=> subActive(e)}>New Client Form</Link></li>
                            <li><a href="https://scratchpay.com/consumer/9448c3f336673f2209ae8b8a1c9c66d6/daytime-veterinary-hospital/Jacksonville-FL-32216/sunshine-animal-clinic" target="_blank" rel="noopener noreferrer">Scratchpay</a></li>
                            <li><a href="https://www.carecredit.com/go/984QSJ/" target="_blank" rel="noopener noreferrer">CareCredit</a></li>
                          </ul>
                        </li>


                      </ul>
                    </div>
                    <div className={`header-action d-none d-md-block`}>
                      <ul>
                        <OverlayTrigger placement="top" overlay={renderClientFormTooltip}>
                          <li className="header-form"><Link to="/new-client-page"><i className="fa fa-file-invoice" /></Link></li>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={renderAppointmentTooltip}>
                          <li className="header-appointment"><Link to="/book-an-appointment"><i className="fa fa-calendar-check"/></Link></li>
                        </OverlayTrigger>

                        <li className={`header-btn ${windowWidth < 1280 ? 'd-none' : ''}`}><Link to="/contact-us" className="btn">Visit Us <img src="img/icon/w_pawprint.png" alt="" /></Link></li>
                      </ul>
                    </div>
                  </nav>
                </div>

                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fas fa-times" /></div>
                    <div className="nav-logo"><Link to="/"><img src="img/logo/logo.png" alt="" title='true' /></Link>
                    </div>
                    <div className="menu-outer">

                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="blank" rel="noopener noreferrer"><span className="fab fa-facebook-f" /></a></li>
                        <li><a href="https://www.facebook.com/sunshineanimalclinic" target="blank" rel="noopener noreferrer"><span className="fab fa-instagram" /></a></li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />

              </div>
            </div>
          </div>
          <div className="header-shape" style={{backgroundImage:"url('img/bg/header_shape.png')"}}/>
        </div>


      </header>
    )
}

export default Header
