import React from 'react';
import Appointment from '../components/BookAnAppointment/Appointment'
import Newsletter from '../components/NewsLetter'
// import BlogsHome from '../components/BlogsHome'
import Testimonial from '../components/Testimonial'

function BookAnAppointment() {

  return (

		  <main>
			<Appointment/>
			<br></br><br></br><br></br>
			{/* <BlogsHome/> */}
			<Testimonial/>
			<br></br><br></br><br></br>
			<Newsletter/>
		  </main>

  )
}

export default BookAnAppointment;
