import React from 'react';

function VaccinationFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageVaccination.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs </h5>
                  <h2 className="title">Vaccination</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Is it important to vaccinate?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      Yes! Pets should receive vaccinations to guard them against a variety of severe and highly contagious diseases. The extensive use of immunizations over the past century has, according to experts, saved millions of animals from illness and death. Vaccination is still strongly advised despite the fact that several once-common diseases have now become uncommon since these dangerous disease agents are still present in the environment.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How does my pet's lifestyle affect its vaccination program?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      While some pets prefer to stay indoors and have little chance of contracting an infectious disease, others are constantly exposed to both infectious disease and other animals, wildlife, and other pets as a result of their activity. Other animals are located in places where they are more likely to get infectious diseases. Differences in lifestyle highlight how crucial it is to tailor a vaccination schedule to each patient.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Are there risks associated with vaccination?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                      Millions of animals have been shielded by vaccines against illness and demise brought on by infectious diseases. But there is some danger involved with every medical operation. Fortunately, major adverse reactions to vaccinations are extremely rare. By carefully picking immunizations based on each pet's unique needs and by selecting the right injection sites, veterinarians reduce danger. 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default VaccinationFAQ;
