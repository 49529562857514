import React from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick'


function AboutUs() {

  const settings = {
    dots: false,
	infinite: true,
	speed: 1000,
	autoplay: true,
	arrows: false,
	autoplaySpeed: 8000,
	slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				speed: 1000,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				speed: 1000,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				speed: 1000,
			}
		},
	]
  }
  return (
	  <section className="inner-breeder-area breeder-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
              <div className="adoption-content">
                <h5 className="sub-title">About Us</h5>
                <h2 className="title">A <span>Full-Service</span> Animal Hospital in Jacksonville</h2>
                <p>You receive more than simply a veterinary expert when you enter our doors; you receive a partner. A staff who will anticipate your needs and those of your pets all while caring for your pets as if it were their own. We promise not to let them or you down.</p>
                <div className="adoption-list">

                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
              <div className="breeder-info-wrap">
                <div className="row no-gutters justify-content-center">
                  <div className="col-md-6">
                    <Slider className="breeder-active slick h-100" {...settings}>
                      {
                        [1, 2, 3, 4].map(num => (
                          <div key={num} className="breeder-item h-100">
                            <img className="d-inline-block h-100" src={`img/about/About${num}.jpg`} alt="" />
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="breed-services-info" style={{backgroundImage:'url("img/bg/breed_services_bg.jpg")'}}>
                      <h5 className="sub-title">A Clinic Where</h5>
                      <h3 className="title">Compassion Matters</h3>
                      <p>Sunshine Animal Clinic has a team of dedicated Veterinarian and Assistants that are here to support your pets' health.</p>
                      <Link to="/contact-us" className="btn">Visit Us Today! <img src="img/icon/w_pawprint.png" alt="" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="counter-area mt-70">
        </div>
        </div>
        
      </section>
  )
}
export default AboutUs;
