import React from 'react';
import ServicesSidebar from './ServicesSidebar';

function Wellness() {
  return (
	   <section className="breeder-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="breeder-details-content">
                <h4 className="title animated animatedFadeInUp fadeInUp">Cat & Dog Physical Wellness Exam</h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">Sunshine Animal Clinic</h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                  <div className="services-detail-content">
                    <p>At Sunshine Animal Clinic, we believe that regular Physical Wellness Exam is crucial to the well-being of your pets’. </p>
                    <p>By bringing in your cat or dog for Physical Wellness Exam, it gives us a chance to evaluate the overall health of your pet while providing our veterinarians a baseline for their unique bodies and tendencies. The physical wellness exam keeps us informed about your pets' health and enables us to spot any major health concerns early on and intervene to stop them from getting worse. </p>
                    <span>Most diseases can be best treated by being detected early, when it is the simplest and least expensive to treat. </span>
                    <br></br><br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">What will the Veterinarian check during a Physical Wellness Exam?</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">What you need to know</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <p>During the Wellness Exam, the veterinarian will ask you questions regarding your pet's lifestyle, such as their diet, whether they spend time outdoors, and if they have any symptoms of vomiting, diarrhea, coughing, or sneezing.</p>
                    <p>If you're concerned about some unusual habits your pet is doing at home, or have any general advice questions like nutrition or bathing, be sure to ask the Veterinarian during this visit!</p>
                    <span>While the goal of the Wellness Exam is for your pet to be as healthy as possible, it is important for us that you leave our office feeling confident and stress free.</span>
                    <br></br>
                    <p> During your visit, the veterinarian will also perform a full body health evaluation on your cat or dog. This includes:</p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Skin and fur</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Gum color, temperature, and pulse</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Heart and lungs</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Abdomen and digestive system</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Urinary tract and genitals</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Teeth and mouth</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Eyes, ears, nose, and throat</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Muscles and joint</strong></p>
                    <p><i className="fa fa-chevron-circle-right" /> <strong>Lymph nodes</strong></p>
                  </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ServicesSidebar />
            </div>
          </div>
        </div>
      </section>
  )
}

export default Wellness;
