import React from 'react';


import HeartwormBreadCrumb from '../components/serviceDetails/HeartwormBreadCrumb';
import Heartworm from '../components/serviceDetails/Heartworm';
import HeartwormPricing from '../components/serviceDetails/HeartwormPricing'
import HeartwormFAQ from '../components/serviceDetails/HeartwormFAQ';
import Newsletter from '../components/NewsLetter';
// import BlogsHome from '../components/BlogsHome'

function ServicesInternalMedicine() {
  return (
	  <main>
		<HeartwormBreadCrumb/>
		<Heartworm />
		<HeartwormPricing/>
		<HeartwormFAQ />
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesInternalMedicine;
