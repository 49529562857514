import React from 'react';

import Dental from '../components/serviceDetails/Dental';
import DentalPricing from '../components/serviceDetails/DentalPricing'
import DentalBreadCumb from '../components/serviceDetails/DentalBreadCumb';
import DentalFAQ from '../components/serviceDetails/DentalFAQ';
// import BlogsHome from '../components/BlogsHome';
import Newsletter from '../components/NewsLetter';

function ServicesDental() {
  return (
	  <main>
		<DentalBreadCumb/>
		<Dental/>
		<DentalPricing/>
		<DentalFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesDental;
