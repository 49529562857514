import React from 'react';
import ServicesSidebar from './ServicesSidebar';
import Accordion from 'react-bootstrap/Accordion';

function Deworming() {
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title animated animatedFadeInUp fadeInUp">
                Pet Deworming
              </h4>
              <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                Sunshine Animal Clinic
              </h6>
              <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                {' '}
              </span>
              <div className="services-detail-content">
                <p>
                  Regularly deworming your pet helps keep their digestive system
                  happy and healthy.
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Deworming for Cats
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  Cat & Kitten Deworming Services
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      Kittens: Kitten Deworming Schedule
                    </Accordion.Header>
                    <Accordion.Body>
                      Initiate treatment at 6 weeks; repeat every month until 6
                      months old. After 6 months of age, worming is recommended
                      every 3 months.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {' '}
                      Deworming Adult Cats: Cat Deworming Schedule
                    </Accordion.Header>
                    <Accordion.Body>
                      Treatment should be given every 3 months. If on an
                      intestinal parasite combination product, have a fecal test
                      performed every year and treat appropriately.
                      <br></br>
                      <br></br>
                      If not on an intestinal parasite combination product, have
                      a fecal test performed 2-4 times per year and treat
                      appropriately
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Deworming for Dogs
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  Dog & Puppy Deworming
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      Deworming Schedule for Puppies
                    </Accordion.Header>
                    <Accordion.Body>
                      Initiate treatment at 2 weeks; repeat at 4, 6, and 8 weeks
                      of age, and then put on a monthly treatment that controls
                      intestinal parasites, then monthly until 6 months of age.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {' '}
                      Adult Dogs: Dog Deworming Schedule
                    </Accordion.Header>
                    <Accordion.Body>
                      Treatment should be given monthly. If on an intestinal
                      parasite combination product, have a fecal test performed
                      every year and treat appropriately. If not on an
                      intestinal parasite combination product, have a fecal test
                      performed 2-4 times per year and treat appropriately. Also
                      monitor and eliminate parasites in pet’s environment.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Deworming;
