import React from 'react';

function HeartwormFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageHeartworm.jpg")'}} />
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Heartworm Disease</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Do I need a prescription for my pet's heartworm preventive medication?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        Yes. A licensed veterinarian must prescribe or administer heartworm preventives, according to the FDA's U.S. Food and Drug Administration (FDA) labeling. This implies that heartworm prevention products must be obtained through your veterinarian or from a pet pharmacy with a prescription.
                        <p></p>
                        A heartworm test is typically performed by the veterinarian before prescribing a heartworm preventive to ensure that your pet does not already have adult heartworms. This is done because administering preventives can result in uncommon but potentially serious reactions that could be harmful or even fatal. Preventative medicine is provided if the heartworm test is negative.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          I have missed 2 months of heartworm prevention for my dog, what should i do?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        Please call us and consult with one of our veterinarians, and immediately re-start your dog's monthly preventive care right away.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Are there any vaccines for heartworm disease?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        No, there isn't a commercially available vaccination to prevent heartworm disease in dogs or cats as of right now.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default HeartwormFAQ;
