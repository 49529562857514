/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'
import PricingCard from './PricingCard'

function DentalPricing() {

  return (
    <section className="pricing-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Price List</h5>
                <h2 className="title">Dental Cleaning and Polish Bundle</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Dental Package 1" price="250" label1="starting at" availability="Monday - Saturday"/>
            <PricingCard title="Dental Package 2" price="350" label1="starting at" availability="Monday - Saturday"/>
            <PricingCard title="Dental Package 3" price="450" label1="starting at" availability="Monday - Saturday"/>

          </div>
        </div>
    </section>
  )
}

export default DentalPricing;
