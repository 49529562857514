import React from 'react';
import ServicesSidebar from './ServicesSidebar';
import Accordion from 'react-bootstrap/Accordion';

function Vaccination() {
  return (
	   <section className="breeder-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="breeder-details-content">
                <h4 className="title animated animatedFadeInUp fadeInUp">Cat & Dog Vaccinations</h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">Sunshine Animal Clinic</h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                  <div className="services-detail-content">
                    <p>At Sunshine Animal Clinic, we believe that vaccinating your furry pet is one of the most important aspects of caring for your pet’s health against possible diseases.</p>
                    <p>It is important for you to consult with our veterinarian about the unique risks of living in our region. We will be happy to discuss the benefits of protecting your pets health with vaccinations, as well as, provide you with information on the required vaccination for your furry animal.</p>
                    <span>Sunshine Animal Clinic weighs the risks facing your pet and will tailor the vaccine protocol to your pet’s health and lifestyle.</span>
                    <br></br><br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Puppy Vaccines</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Puppy Vaccine Schedule</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> 1st Package: 6-10 week Vaccine</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> DHLPP / DHPP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming  (Pyrantel)</p>
                            <p className="tab"><i className="flaticon-tick"/> Fecal Exam</p>
                            <i>*Follow up Boosters Vaccines are <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header> 2nd Package: 10-14 week Vaccine</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> DHLPP / DHPP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming  (Pyrantel)</p>
                            <p className="tab"><i className="flaticon-tick"/> Bordetella Vaccine</p>
                            <i>*Follow up Boosters Vaccines are <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                     <Accordion.Item eventKey="2">
                        <Accordion.Header> 3rd Package: 14-16 week Vaccine</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> DHLPP / DHPP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming  (Pyrantel)</p>
                            <p className="tab"><i className="flaticon-tick"/> Bordetella Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Rabies Vaccine</p>
                            <i>*Mandatory $22 Pet Registration Tag for Duval County <strong>not</strong> Included in Package</i>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Dog Vaccines</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Canine Adult Vaccine</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> Annual Package</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> DHLPP / DHPP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Bordetella Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Rabies Vaccine (1 year / 3 year)</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming / Pyrantel</p>
                            <p className="tab"><i className="flaticon-tick"/> Fecal Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> Heartworm Test</p>
                            <i>*Mandatory $22 Pet Registration Tag for Duval County <strong>not</strong> Included in Package</i>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Kitten Vaccines</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Kitten Vaccine Schedule</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> 1st Package: 6-10 week Vaccine</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> FVRCP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming / Pyrantel</p>
                            <p className="tab"><i className="flaticon-tick"/> Fecal Exam</p>
                            <i>*Follow up Boosters Vaccines are <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header> 2nd Package: 10-14 week Vaccine</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> FVRCP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming / Pyrantel</p>
                            <i>*Follow up Boosters Vaccines are <strong>not</strong> Included</i>
                          </Accordion.Body>
                      </Accordion.Item>
                     <Accordion.Item eventKey="2">
                        <Accordion.Header> 3rd Package: 14-16 week Vaccine</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> FVRCP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming / Pyrantel</p>
                            <p className="tab"><i className="flaticon-tick"/> Rabies Vaccine</p>
                            <i>*Mandatory $22 Pet Registration Tag for Duval County <strong>not</strong> Included in Package</i>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Cat Vaccines</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Feline Adult Vaccines</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> Annual Package</Accordion.Header>
                          <Accordion.Body>
                            <p className="tab"><i className="flaticon-tick"/> Complete Physical Exam</p>
                            <p className="tab"><i className="flaticon-tick"/> FVRCP Vaccine</p>
                            <p className="tab"><i className="flaticon-tick"/> Rabies Vaccine (1 year / 3 year)</p>
                            <p className="tab"><i className="flaticon-tick"/> Deworming / Pyrantel</p>
                            <p className="tab"><i className="flaticon-tick"/> Fecal Exam</p>
                            <i>*Mandatory $22 Pet Registration Tag for Duval County <strong>not</strong> Included in Package</i>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br></br>
                  </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ServicesSidebar />
            </div>
          </div>
        </div>
      </section>
  )
}

export default Vaccination;
