import React from 'react';

import Vaccination from '../components/serviceDetails/Vaccination'
import VaccinationPricing from '../components/serviceDetails/VaccinationPricing'
import VaccinationBreadCumb from '../components/serviceDetails/VaccinationBreadCumb'
import VaccinationFAQ from '../components/serviceDetails/VaccinationFAQ'
// import BlogsHome from '../components/BlogsHome'
import Newsletter from '../components/NewsLetter'

function ServicesVaccination() {
  return (
	  <main>
		<VaccinationBreadCumb/>
		<Vaccination/>
		<VaccinationPricing />
		<VaccinationFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesVaccination;
