/* global $ */
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

function OurServices() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000
        }
      }
    ]
  };

  const handleClickPromotions = () => {
    if (window.location.pathname === '/services') {
      $('html, body').animate({
        scrollTop: $("#services-promotion").offset().top - ($('#sticky-header').height() / 4)
      }, 500);
    }
  };

  return (
    <section className="inner-breeder-area breeder-bg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
            <div className="adoption-content">
              <h5 className="sub-title">Our Services</h5>
              <h2 className="title">
                Because Pets are <span>Family</span>
              </h2>
              <p>
                Here at Sunshine Animal Clinic, we are pleased to have a large
                number of veterinary services available for our patients. We are
                proud to be able to serve Jacksonville and our surrounding
                communities to give your pet the best care that they deserve!
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    Our staff at Sunshine Animal Clinic is filled with
                    passionate animal lovers who are here to answer any
                    questions about services that you may have.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
            <div className="breeder-info-wrap">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-6">
                  <Slider
                    className="breeder-active owl-carousel h-100"
                    {...settings}
                  >
                    {[1, 2, 3, 4].map((num) => (
                      <div key={num} className="breeder-item h-100">
                        <img
                          className="d-inline-block h-100"
                          src={`img/services/OurServices${num}.jpg`}
                          alt=""
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="col-md-6">
                  <div
                    className="breed-services-info"
                    style={{
                      backgroundImage: 'url("img/bg/breed_services_bg.jpg")'
                    }}
                  >
                    <h5 className="sub-title">Complete veterinary care.</h5>
                    <h3 className="title">Compassionate & Effective</h3>
                    <p>
                      It is our top priority to keep your pet healthy by
                      offering a wide range of quality services at a reasonable
                      price.
                    </p>
                    <Link to="/services#promotions" onClick={handleClickPromotions} className="btn">
                      See our Promotions
                      <img src="img/icon/w_pawprint.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-area mt-70"></div>
      </div>
    </section>
  );
}

export default OurServices;
