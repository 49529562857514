import React from 'react';

function InternalMedicineFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageInternal.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Internal Medicine</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Why Did My Veterinarian Send Me To An Internal Medicine Specialist?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      Multiple illness conditions (including kidney disease, heart disease, and high blood pressure) are frequently present in pets, making their care complex and difficult. Specialized testing could sometimes be advised to help pet owners figure out what's wrong with their animal. For instance, a pet with persistent diarrhea may require a colonoscopy or an ultrasound (sonogram) of the abdomen to assist identify the origin of the diarrhea.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Why Should I Get My Pet’s Medications From A Veterinarian Or Human Pharmacy? It’s Usually Cheaper Online?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      Sometimes online pharmacies are not under the same regulations as veterinary hospitals or human pharmacies. Therefore, medications may not be stored properly and subtle variations (generic vs brand name) can be overlooked as insignificant. Additionally, pet owners have been sold fraudulent medications online. Sunshine Animal Clinic can help you find the most cost-effective options for your pet.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default InternalMedicineFAQ;
