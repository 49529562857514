/* global $, swal */
import React from 'react';
import ContactAddress from './ContactAddress';

function ContactForm() {
  /* - Contact Form */
  const submitForm = (event) => {
    event.preventDefault();
    const mydata = $('form').serialize();

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: 'https://api.sunshineanimalclinic.com/emailer/contact_service.php',
      data: mydata,
      success: function (data) {
        console.log(data);
        if (data['type'] === 'error') {
          $('#alert-msg').html(data['msg']);
          $('#alert-msg').removeClass('shakeshake');
          $('#alert-msg').removeClass('text-success');
          $('#alert-msg').addClass('text-danger');
          $('#alert-msg').show();
          setTimeout(() => {
            $('#alert-msg').addClass('shakeshake');
          }, 100);
        } else {
          $('#alert-msg').hide();
          $('#alert-msg').addClass('text-success');
          $('#alert-msg').removeClass('text-danger');
          $('#first-name').val('');
          $('#last-name').val('');
          $('#input_phone').val('');
          $('#input_email').val('');
          $('#input_subject').val('');
          $('#textarea_message').val('');
          swal(
            'Thank you for contacting us. We will get back to you as soon as possible!'
          );
        }
      },
      error: function (xhr, textStatus, errorThrown) {
        console.log(xhr, textStatus, errorThrown);
      }
    });
    return false;
    // $('#contact-form').attr('action', 'saveQuery').submit();
  };

  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                <h5 className="sub-title text-center">Contact Us</h5>
                <h2 className="title text-center">
                  Join Our Family<span>!</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <div className="text-center">
                  <p>
                    If you have any additional questions or concerns, please
                    fill in the form below and we will get back to you as soon
                    as possible.
                  </p>
                </div>
                <form className="contact-form">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-grp">
                        <label htmlFor="contact-first-name">
                          First Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="first-name"
                          placeholder="First Name"
                          name="contact-first-name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-grp">
                        <label htmlFor="contact-last-name">
                          Last Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="last-name"
                          name="contact-last-name"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-grp">
                        <label htmlFor="contact-email">
                          Email <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="input_email"
                          name="contact-email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-grp">
                        <label htmlFor="contact-phone">
                          Phone Number <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="input_phone"
                          name="contact-phone"
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <label htmlFor="contact-subject">
                      Subject <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="input_subject"
                      name="contact-subject"
                      placeholder="Subject..."
                      required
                    />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="contact-message">
                      Your Message <span>*</span>
                    </label>
                    <textarea
                      id="textarea_message"
                      name="contact-message"
                      placeholder="Notes to our Staff..."
                      defaultValue={''}
                      required
                    />
                  </div>

                  <div id="alert-msg" className="mb-3"></div>
                  <button
                    onClick={submitForm}
                    type="button"
                    className="btn rounded-btn"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>

            <ContactAddress />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
