/* global $, swal */
import React from 'react';

function Newsletter() {
  const validEmail = (email) => {
    const re =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const noErrors = () => {
    if (
      !validEmail($('#newsletter-email').val()) ||
      !$('#newsletter-full-name').val().trim()
    ) {
      return false;
    }
    return true;
  };

  const submitNewsletter = (e) => {
    e.preventDefault();
    if (!noErrors()) {
      swal('Please fill in all fields correctly');
      return;
    }
    const payload = {
      email: $('#newsletter-email').val(),
      FNAME: $('#newsletter-full-name').val(),
      LNAME: ''
    };

    $.post(
      'https://api.sunshineanimalclinic.com/emailer/newsletter_service.php',
      payload
    ).done(handleNewsletterRes);
  };

  const handleNewsletterRes = (res) => {
    var json = JSON.parse(res);
    if (json.status === 200) {
      swal('Subscribed', 'Thank you for subscribing!');
      $('#newsletter-full-name').val('');
      $('#newsletter-email').val('');
    } else {
      swal(
        'There was an error subscribing you to our newsletter. Please try again.'
      );
    }
  };

  return (
    <div className="newsletter-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="newsletter-wrap">
              <div className="newsletter-content">
                <h2 className="title">Subscribe To Our Newsletter</h2>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
              </div>
              <div className="newsletter-form">
                <form onSubmit={submitNewsletter}>
                  <input
                    id="newsletter-full-name"
                    type="name"
                    placeholder="Enter Your Name..."
                  />
                  <input
                    id="newsletter-email"
                    type="email"
                    placeholder="Enter Your Email..."
                  />
                  <button type="submit" className="btn">
                    Subscribe
                  </button>
                </form>
              </div>
              <div className="newsletter-shape">
                <img src="img/images/newsletter_shape01.png" alt="" />
              </div>
              <div className="newsletter-shape shape-two">
                <img src="img/images/newsletter_shape02.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Newsletter;
