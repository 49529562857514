import React, { useEffect } from 'react';

function Appointment() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://us.vetstoria.com/js/oabp-widget-require.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container-fluid pt-110 pb-110">
      <div className="section-padding"></div>
      <div className="container">
        <div className="contact-title mb-20 text-center text-center">
          <h5 className="sub-title">Sunshine Animal Clinic</h5>
          <h2 className="title">
            Schedule an Appointment<span>.</span>
          </h2>
        </div>
        <div className="contact-wrap-content animated">
          <div className="text-center">
            <p>
              We are so happy that you have chosen Sunshine Animal Clinic to
              take care of your pets’ health care needs.
              <br></br>
              Please fill out the following information to book an appointment.
            </p>
          </div>
        </div>
        <div
          id="oabp-widget"
          data-mode="html5"
          data-domain="us.vetstoria.com"
          data-site-hash="60a438737c55e"
          data-mobile-message="Touch Here to Make an Appointment"
          data-auto-mobile-redirection="false"
          data-force-desktop-view="false"
          data-offset="0"
          data-theme-customisation="F34427,F34427,F34427,ffffff,FEC134,FEC134,FEC134,FEC134,FEC134,ececec"
        ></div>
        <div className="section-padding"></div>
      </div>
    </div>
  );
}
export default Appointment;
