import React from 'react';
import Slider from 'react-slick'

function Testimonial() {
  const settings = {
  dots: true,
	infinite: true,
	speed: 2000,
	autoplay: false,
	arrows: false,
	autoplaySpeed: 4000,
	slidesToShow: 2,
	slidesToScroll: 2,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				speed: 1000,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				speed: 1000,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				speed: 1000,
			}
		},
	]
  }
  return (
      <section className="testimonial-area testimonial-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Testimonials</h5>
                <h2 className="title">What Our Clients Say</h2>
              </div>
            </div>
          </div>
          <Slider className="row testimonial-active"{...settings}>
            <div className="col">
              <div className="testimonial-item">
                <div className="testi-content">
                  <p>"Dr. Patel and his staff at Sunshine Animal Clinic take great care of our dog. We cannot say enough good things about him. His prices are reasonable, he is very courteous and understanding, and is a great diagnostician. Our pup loves him. You won’t be disappointed with him or his veterinary practice."</p>
                  <div className="testi-avatar-info">
                    <h5 className="title">Michele Dzina</h5>
                    <span>Client</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="testimonial-item">
                <div className="testi-content">
                  <p>“Absolutely would recommend to anyone who needs an annual vet or has a small emergency. They don’t break the bank and are very fast and upfront about the care for your pet. I really appreciate the staff and Drs who make it great!”
                    <br></br><br></br><br></br>
                  </p>
                  <div className="testi-avatar-info">
                    <h5 className="title">Jenna Cofer</h5>
                    <span>Client</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="testimonial-item">
                <div className="testi-content">
                  <p>“Excellent care and concern for your pet's health and wellbeing. Dr Patel is a kind friend to my dog and cats and they are comfortable seeing him. The office is new and well-equipped. Open 7 days a week if your pet has a problem that needs immediate attention. Prices are reasonable.”</p>
                  <div className="testi-avatar-info">
                    <h5 className="title">Constance Mays</h5>
                    <span>Client</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="testimonial-item">
                <div className="testi-content">
                  <p>“Dr. Patel has been nothing but honest, kind, and professional. He is very open with estimates and fair with prices so that you can focus on the health of your pet rather than your bank account. Him and the staff have always shown kindness and genuine care to my animals. Highly recommend Sunshine!”</p>
                  <div className="testi-avatar-info">
                    <h5 className="title">Soojie Chin</h5>
                    <span>Client</span>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
  )
}

export default Testimonial;
