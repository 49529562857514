import React from 'react';
import ServicesSidebar from './ServicesSidebar';

function SpayNeuter() {
  return (
	   <section className="breeder-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="breeder-details-content" >
                <h4 className="title animated animatedFadeInUp fadeInUp">Spay and Neuter Specialists</h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">Sunshine Animal Clinic</h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                  <div className="services-detail-content">
                    <p>At Sunshine Animal Clinic, our veterinarians specialize in Spay and Neuter procedures. We have a team of experienced veterinarians, veterinary technicians and assistants that are dedicated for surgeries Monday through Saturday every week.</p>
                    <p>Spaying and neutering a dog or car is an important part of pet population control while also benefiting you and your pet by:</p>
                    <p className="tab"><i className="flaticon-tick"/> <strong>Decreasing aggression towards your pet and by your pet</strong></p>
                    <p className="tab"><i className="flaticon-tick"/> <strong>Lowering the incidence of mammary, prostatic, or testicular cancers in your pet</strong></p>
                    <p className="tab"><i className="flaticon-tick"/> <strong>Decreasing roaming and bolting behavior by your pet</strong></p>
                    <p className="tab"><i className="flaticon-tick"/> <strong>Decreasing dominance behavior by your pet</strong></p>
                    <p>As with all surgeries at Sunshine Animal Clinic, our strategy is to provide top quality care. Our number one priority is your pet’s well-being, so we focus on safe anesthesia, proficient surgeons, and effective pain control.</p>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Spaying & Neutering a Cat</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">What you need to know</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <p>Consider having your kitten spayed or neutered after it is 6 months old. The number of homeless cats killed in shelters can be decreased with the help of spaying and neutering. Animals who have been spayed live longer and are less likely to get certain cancers. By lessening the desire to roam, engage in conflict with other cats and animals, and sustain injuries, spaying improves your pet's health.</p>
                    <p>The danger of mammary (breast) cancer is almost completely eliminated in female cats by spaying her prior to her first heat cycle. Additionally, female cats that have been spayed are protected from uterine and ovarian cancer. Additionally, spaying aids in lowering the likelihood of difficulties during pregnancy and delivery.</p>
                    <p>Male cat neutering primarily improves behavior, but it also lowers the possibility of your pet developing testicular cancer as they age.</p>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Spaying & Neutering a Dog</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">What you need to know</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <p>Consider having your puppy spayed or neutered whenever it is between 4 and 6 months old. While there are many variables that affect when to spay and neuter, each owner should speak with one of our veterinarians about their unique situation.</p>
                    <p>Male dogs who have been neutered become less aggressive and are less prone to stray from their homes, as well as preventing the development of testicular cancer. As a result, they are less inclined to engage in combat with other pets, thus ensuring their safety.</p>
                    <p>Female dogs who are spayed live better, longer lives with fewer behavioral problems and are protected from significant health disorders including mammary cancer and pyometra, a potentially fatal uterine infection.</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ServicesSidebar />
            </div>
          </div>
        </div>
      </section>
  )
}

export default SpayNeuter;
