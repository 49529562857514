import React from 'react';
import ServicesSidebar from './ServicesSidebar';
import Accordion from 'react-bootstrap/Accordion';

function Heartworm() {
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title animated animatedFadeInUp fadeInUp">
                Heartworm Prevention
              </h4>
              <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                Sunshine Animal Clinic
              </h6>
              <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                {' '}
              </span>
              <div className="services-detail-content">
                <p>
                  In the United States and many other countries across the
                  world, heartworm illness in pets is a serious and potentially
                  fatal condition. Heartworm disease affects pets and is brought
                  on by foot-long worms (heartworms) that reside in the heart,
                  lungs, and blood arteries nearby. These worms cause heart
                  failure, severe lung disease, and harm to other body parts. 
                </p>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      Heartworms Disease In Dogs
                    </Accordion.Header>
                    <Accordion.Body>
                      The dog serves as a natural host for heartworms, allowing
                      them to develop into adults, reproduce, and create
                      offspring. Dogs have been known to have hundreds of worms
                      in their bodies, and their numbers can grow if left
                      untreated. Heartworm disease damages the heart, lungs, and
                      arteries permanently and can have an impact on a dog's
                      health and quality of life even after the parasites have
                      disappeared. Because of this, prevention is always the
                      best course of action, and when treatment is required, it
                      should be administered as early in the course of the
                      illness as feasible.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {' '}
                      Heartworms Disease In Cats
                    </Accordion.Header>
                    <Accordion.Body>
                      Heartworm disease in cats is very different from heartworm
                      disease in dogs as cats are an unusual host for heartworms
                      because few worms mature to adulthood. Heartworm-infected
                      cats frequently have none or only one to three adult worms
                      in their bodies. This means that heartworm sickness in
                      cats is frequently misdiagnosed, but it's crucial to
                      realize that even juvenile worms can harm an animal by
                      causing heartworm associated respiratory disease (HARD).
                      Cats can only be protected from the effects of heartworm
                      disease through prevention because the medication used to
                      treat heartworm infections in dogs cannot be administered
                      in cats.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  What Are The Signs Of Heartworm Disease In Dogs?
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  What you need to know
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  Many dogs have little or no symptoms in the early stages of
                  the disease. The likelihood that symptoms will appear
                  increases with the length of the infection. Dogs that are
                  active, have severe heartworm infections, or have other health
                  issues may exhibit strong clinical indications.
                </p>
                <p>
                  A mild chronic cough, resistance to exercise, weariness after
                  moderate activity, decreased appetite, and weight loss are all
                  potential heartworm disease symptoms. Pets who have heartworm
                  illness may eventually experience heart failure and a large
                  belly because of an excess of fluid in the abdomen. Large
                  heartworm infestations in dogs can cause sudden obstructions
                  in the heart's blood flow, which can result in a potentially
                  fatal form of cardiovascular collapse. The symptoms of caval
                  syndrome include dark crimson or coffee-colored urine, pale
                  gums, and an abrupt onset of difficult breathing. Few dogs
                  survive without prompt surgical removal of the heartworm
                  obstruction.
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Heartworm Testing
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  What you need to know
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  Heartworm infection is a dangerous, developing illness. The
                  earlier it is detected, the more likely it is that the pet
                  will recover. When a dog or cat has heartworms, there are very
                  few, if any, early symptoms of disease, so it's crucial to
                  check for them with a heartworm test performed by a
                  veterinarian. The test only needs a tiny amount of blood from
                  your pet, and it works by looking for heartworm proteins. At
                  Sunshine Animal Clinic, our experienced veterinarians are
                  equipped to handle heartworm testing in-house giving you
                  results within minutes. Additional testing might be requested
                  if your pet tests positively.
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  When Should My Dog Be Tested?
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  Know your options
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  Every dog should get an annual heartworm test, which is
                  typically performed as part of a regular checkup for
                  preventive treatment. The testing and timing recommendations
                  are as follows:
                </p>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> Puppies</Accordion.Header>
                    <Accordion.Body>
                      Although puppies under 7 months old can begin heartworm
                      prevention without a heartworm test (it takes at least 6
                      months for a dog to test positive after it has been
                      infected), they should be tested 6 months after your
                      initial visit, again 6 months later, and then annually
                      after that to make sure they are heartworm-free.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header> Adult Dogs</Accordion.Header>
                    <Accordion.Body>
                      Prior to beginning heartworm prevention, adult dogs over 7
                      months old who had not previously been on a preventive
                      need to be tested. They must also be tested after six
                      months, after a year, and then once a year after that.
                      <br></br>
                      <br></br>
                      Even when dogs are treated year-round for heartworms,
                      annual testing is required to make sure the preventative
                      regimen is effective. Although heartworm medicines are
                      quite successful, dogs can still contract the disease. A
                      single missed dose of a monthly prescription, or giving it
                      late, can render your dog defenseless. Your dog may vomit
                      or spit out a heartworm tablet or rub off a topical
                      medicine even if you administer it as directed. Although
                      they are very effective, heartworm preventives are not
                      perfect. You won't know your dog requires treatment if you
                      don't get your dog tested.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  What happens if my dog tests positive for heartworms?
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  Know your options
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  The good news is that the majority of heartworm-infected dogs
                  can be successfully treated. If your dog is exhibiting
                  symptoms of a sickness, the objective is to first stabilize
                  him before killing all adult and young worms with the least
                  number of negative effects possible.
                </p>
                <p>Here's what you should expect if your dog tests positive:</p>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> Confirm the diagnosis</Accordion.Header>
                    <Accordion.Body>
                      An extra, distinct test should be used to confirm the
                      diagnosis once your pet tests positive for an antigen
                      test. Your veterinarian will want to make certain that
                      therapy is required because the heartworm treatment
                      regimen is both complex and costly.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header> Restrict exercise</Accordion.Header>
                    <Accordion.Body>
                      It could be challenging to follow this rule, especially if
                      your dog is used to being active. However, as soon as the
                      diagnosis is established, your dog's typical physical
                      activities must be limited since physical activity speeds
                      up the rate at which the heartworms destroy the heart and
                      lungs. Your dog should be less active the more serious the
                      symptoms are.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {' '}
                      Stabilize your dog's disease
                    </Accordion.Header>
                    <Accordion.Body>
                      It could be necessary to stabilize your dog's condition
                      with the appropriate therapy prior to starting the actual
                      heartworm treatment. The procedure can take several months
                      in severe heartworm disease cases or when a dog has
                      another significant condition.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header> Administer treatment</Accordion.Header>
                    <Accordion.Body>
                      After determining that your dog is healthy and prepared
                      for heartworm treatment, your vet will suggest a treatment
                      plan that includes a number of steps. The American
                      Heartworm Society gives recommendations for creating this
                      strategy. The success rate of treatment in dogs with no or
                      minor heartworm disease symptoms, such as coughing or
                      exercise intolerance, is high. Although there is a greater
                      chance of complications, more severe disease can still be
                      successfully treated. Dogs with many worms may have little
                      or no symptoms early in the course of the disease, and the
                      severity of heartworm disease does not usually correlate
                      with the severity of symptoms.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {' '}
                      Test (and prevent) for success
                    </Accordion.Header>
                    <Accordion.Body>
                      Your veterinarian will do a heartworm test about 6 months
                      following the end of the treatment to ensure that all
                      heartworms have been eradicated. For the remainder of his
                      life, you should give your dog heartworm prevention
                      year-round to reduce the chance that he may get the
                      disease once more.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Heartworm;
