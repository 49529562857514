/* global swal */
import React, { useEffect } from 'react';
import MainSlider from '../components/MainSlider';
import Testimonial from '../components/Testimonial';
import Newsletter from '../components/NewsLetter';
// import BlogsHome from '../components/BlogsHome'
import AboutUs from '../components/about-us/AboutUs';
import ServiceList from '../components/services/ServiceList';
import Philosophy from '../components/about-us/Philosophy';
import OurServices from '../components/services/OurServices';
import VetGallary from '../components/VetGallary';

function Home() {
  // useEffect(() => {
  //   initPopup();
  // }, []);

  // Welcome popup
  // const initPopup = () => {
  //   if (!document.referrer.includes(window.location.host)) {
  //     setTimeout(() => {
  //       swal({
  //         title: "We've made changes!",
  //         text: "Our team at Sunshine Animal Clinic is very excited to announce our redesigned and updated website. We hope you enjoy your time here and stop by more often!",
  //         confirmButtonText: 'OK'
  //       });
  //     }, 2000);
  //   }
  // };

  return (
    <main>
      <MainSlider />
      <AboutUs />
      <VetGallary />
      <br></br>
      <br></br>
      <OurServices />
      <Philosophy />
      <ServiceList />
      <Testimonial />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* <BlogsHome/> */}
      <Newsletter />
    </main>
  );
}

export default Home;
