import React from 'react';
import AboutUs from '../components/about-us/AboutUs';
import AboutUs2 from '../components/about-us/AboutUs2';
import Philosophy from '../components/about-us/Philosophy';
import Testimonial from '../components/Testimonial';
import Newsletter from '../components/NewsLetter';
// import BlogsHome from '../components/BlogsHome'

function AboutUsPage() {

  return (

		  <main>
			<AboutUs/>
			<Philosophy/>
			<AboutUs2/>
			<Testimonial/>
			{/* <BlogsHome/> */}
			<br></br><br></br><br></br><br></br><br></br>
			<Newsletter/>
		  </main>

  )
}

export default AboutUsPage;
