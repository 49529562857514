import React from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken =
  'pk.eyJ1Ijoic3V2aWsxMiIsImEiOiJjbDlkZGVvOWg0ODlvM3BsZmxrYmdnMjVxIn0.qLL27xdHrMmbgUxsHx9_8w';

// Sample data
const data = [
  {
    location: 'Sunshine Animal Clinic',
    Address: '4131 Southside Blv, Suite 205',
    coordinates: [-81.558415, 30.271806]
  }
];

class Map extends React.Component {
  // Set up states for updating map
  constructor(props) {
    super(props);
    this.state = {
      lng: -81.558415,
      lat: 30.271806,
      zoom: 15.5
    };
  }

  // Create map and lay over markers
  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/suvik12/cl9tce459001x15m7922x7grj',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      cooperativeGestures: true
    });

    data.forEach((location) => {
      new mapboxgl.Marker({ color: '#f04336' })
        .setLngLat(location.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 30 }).setHTML(
            '<h6>' + location.location + '</h6>' + location.Address
          )
        )
        .addTo(map);
    });
  }

  render() {
    return (
      <div>
        <div className="map-container" ref={(el) => (this.mapContainer = el)}>
          <div className="sidebar">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/dir//Sunshine+Animal+Clinic,+4131+Southside+Blvd+Suite+205,+Jacksonville,+FL+32216/@30.2717338,-81.5596129,17.08z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88e5b5760a8fffff:0x7e998f090dd37ac3!2m2!1d-81.5584323!2d30.2717266!3e0"
            >
              <img src="img/contact/street-view.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
