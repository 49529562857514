import React from 'react';
import NewsLetter from '../components/NewsLetter'
import ContactForm from '../components/contact-us/ContactForm'
import Mapbox from '../components/Mapbox'


function ContactPage() {
  return (
		<main>
			
		<ContactForm/>
		<Mapbox/>
		<NewsLetter/>
  
		</main>

  )
}

export default ContactPage;
