import React from 'react';

function SpayNeuterFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageSpay.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Spay &amp; Neuter Surgery</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Is a Spay or Neuter surgery safe?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        As with any other surgery, there is always a small degree of risk but our veterinarians are well experienced when it comes to spay or neuter procedures and are performed very safely under anesthesia.
                        <p></p>
                        At Sunshine Animal Clinic, spay and neuter surgeries are most common and our veterinarians always perform a full pre-surgical checkup of your pet to ensure they are healthy as possible prior to anesthesia.
                        <p></p> 
                        While some pets heal quickly and can get back to a normal routine rapidly, they might need some monitoring and TLC at home.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Is the surgery painful?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        As with any surgery performed at any veterinarian clinics, there may be some discomfort, especially in the first 3-5 days afterward.
                        <p></p>
                        However, we ensure that our veterinarian provide adequate pain control to your pet during the surgery.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        How long will it take for my pet to recover?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                         One of our veterinarians will provide you with specific instructions of what to expect and how to care for your pet after surgery.
                         <p></p>
                        But, as a general rule of thumb, your pet will need close monitoring in the first 12-24 hours (as the effects of anesthesia wear off).
                        <p></p>
                        Post-surgery, your furry pet will need as much rest as possible until they have fully recovered from the surgery. For most pets, it takes about 10-14 days, when their stitches are removed.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        How much does a spay or neuter cost?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                      The cost varies on a number factors like whether it is a dog or a cat, their age, gender, weight and any other medical conditions they may have. 
                      <p></p>
                      The final quote is customized to your pet once one of our veterinarians consult your pet. 
                     <p></p>
                      To schedule an appointment, please give us a <a href="tel:0987654321">call</a>, or <a href="/#">book an appointment</a>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default SpayNeuterFAQ;
