import React from 'react';

function DentalFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageDental.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Pet Dental Health</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                         Is there anything I can do at home to keep my pet's teeth healthy?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      Regular tooth brushing and dental chew toys should be provided for your pet at home. These will aid in plaque removal.
                        <p></p>
                        Do not let them chew on things like bones, toys, or objects that are excessively hard because doing so will harm their teeth. Any queries or worries regarding your pet's dental health should always be directed to your veterinarian.

                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How often does my dog or cat need to have their teeth cleaned?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      Given that it varies greatly from animal to animal, this is a challenging question to answer. The frequency of your pet's dental cleanings depends on a variety of factors, including genetics, jaw structure, at-home oral care, and lifestyle. You will need to <a href="/book-an-appointment">schedule an appointment</a> with one of our veterinarians to determine if your pet needs to have their teeth cleaned.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        How common are dental problems in pets?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                      Over 70% of all dogs and cats over the age of five have periodontal disease, which results in the loss of the tooth support structures. The majority of the time, this process is unpleasant and will eventually result in issues including loose teeth, bacterial bloodstream contamination, and harm to the kidneys, heart, and liver. 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default DentalFAQ;
