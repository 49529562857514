/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'
import PricingCard from './PricingCard'

function HeartwormPricing() {

  return (
    <section className="pricing-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Price List</h5>
                <h2 className="title">Heartworm Test</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Heartworm Test" price="32" label1="physical exam required ($39)" availability="Monday - Saturday"/>
            <PricingCard title="Heartworm Treatment Package" price="529" label1="Starting At" availability="Monday - Saturday"/>

          </div>
        </div>
    </section>
  )
}

export default HeartwormPricing;
