import React from 'react'
import {Link} from 'react-router-dom'

function VetGallary() {


  
  return (
    <section className="adoption-area">
    <div className="container">
      <div className="row align-items-center align-items-xl-end justify-content-center">
        <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
          <div className="adoption-img">
            <img src="img/about/AboutHome.jpg" alt="" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6">
          <div className="adoption-content">
            <h2 className="title">Truly Compassionate <br /> <span>Veterinary Care</span> for Dogs and Cats</h2>
            <p>Our team at Sunshine Animal Clinic provides fear free pet care across Jacksonville and nearby areas.</p>
            <Link to="/book-an-appointment" className="btn">Book an Appointment <img src="img/icon/w_pawprint.png" alt="" /></Link>
          </div>
        </div>
      </div>
    </div>
    <div className="adoption-shape"><img src="img/images/adoption_shape.png" alt="" /></div>
  </section>
  )
}

export default VetGallary;
