import React from 'react';

function BoardingFAQ() {
  return (

	  <section className="faq-area faq-two-bg">
        <div className="faq-two-img" style={{ backgroundImage:'url("img/services/FAQ_imageBoarding.jpg")'}}/>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-7 col-lg-6">
              <div className="faq-wrapper">
                <div className="section-title white-title mb-35">
                  <h5 className="sub-title">FAQs</h5>
                  <h2 className="title">Pet Boarding</h2>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How do I book an appointment for pet boarding?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      Please <a href="tel:0987654321">call us</a> and talk to one of our staff members regarding boarding prior to dropping any pet(s) off for boarding.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Do you offer Medical Boarding?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      Yes. while the prices above reflect non-medical  boarding, please <a href="tel:0987654321">call us</a> for prices regarding Medical Boarding.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What if my pet needs to take medication?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                      Our staff at Sunshine Animal Clinic will gladly administer your pet's medication while they are with us.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        What type of food will my pet eat?
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                      Depending on their breed and size, all of our pets are provided with high-quality pet food.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
      </section>
  )
}

export default BoardingFAQ;
