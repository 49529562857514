import React from 'react';

import InternalMedicine from '../components/serviceDetails/InternalMedicine';
import InternalMedicineBreadCumb from '../components/serviceDetails/InternalMedicineBreadCumb';
import InternalMedicineFAQ from '../components/serviceDetails/InternalMedicineFAQ';
// import BlogsHome from '../components/BlogsHome'
import Newsletter from '../components/NewsLetter';

function ServicesInternalMedicine() {
  return (
	  <main>
		<InternalMedicineBreadCumb/>
		<InternalMedicine/>
		<InternalMedicineFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesInternalMedicine;
