import React from 'react';
import OurServices from '../components/services/OurServices';
import ServiceList from '../components/services/ServiceList'
import ServicesFAQ from '../components/services/ServicesFAQ'
import ServicesPromotion from '../components/services/ServicesPromotion'

import Newsletter from '../components/NewsLetter'

function Services() {
  return (
	  <main>
		<OurServices/>
		<ServiceList/>
		<ServicesFAQ/>
		<ServicesPromotion/>

		<Newsletter/>

	  </main>
  )
}

export default Services;
