import { BrowserRouter as Router, Switch } from 'react-router-dom';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import Home from './pages/Home';
import ContactPage from './pages/ContactPage';
import BlogPage from './pages/BlogPage';
import BlogDetailsPage from './pages/BlogDetailsPage';
import Services from './pages/ServicesPage';
import ServicesWellness from './pages/ServicesWellness';
import ServicesEmergency from './pages/ServicesEmergency';
import ServicesVaccination from './pages/ServicesVaccination';
import ServicesSpayNeuter from './pages/ServicesSpayNeuter';
import ServicesDental from './pages/ServicesDental';
import ServicesInternalMedicine from './pages/ServicesInternalMedicine';
import ServicesHeartworm from './pages/ServicesHeartworm';
import ServicesDeworming from './pages/ServicesDeworming';
import ServicesSkincare from './pages/ServicesSkincare';
import ServicesBoarding from './pages/ServicesBoarding';
import AboutUsPage from './pages/AboutUsPage';
import BookAnAppointment from './pages/BookAnAppointment';
import NewClientPage from './pages/NewClientPage';
import ScrollToTopRoute from './ScrollToTopRoute';

function App() {

  return (
    <div className="app">
      <Router>
        <Header />
        <Switch>
          <ScrollToTopRoute exact={true} path="/">
            <Home />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/about-us">
            <AboutUsPage />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services">
            <Services />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-wellness">
            <ServicesWellness />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-emergency">
            <ServicesEmergency />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-vaccination">
            <ServicesVaccination />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-spay-and-neuter">
            <ServicesSpayNeuter />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-dental">
            <ServicesDental />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-internal-medicine">
            <ServicesInternalMedicine />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-heartworm">
            <ServicesHeartworm />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-deworming">
            <ServicesDeworming />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-skin-care">
            <ServicesSkincare />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/services-boarding">
            <ServicesBoarding />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/blogs">
            <BlogPage />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/blog-details">
            <BlogDetailsPage />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/contact-us">
            <ContactPage />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/book-an-appointment">
            <BookAnAppointment />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/new-client-page">
            <NewClientPage />
          </ScrollToTopRoute>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
