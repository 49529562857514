import React from 'react';
import Slider from 'react-slick'

function AboutUs2() {
  const settings = {
    dots: true,
	infinite: true,
	speed: 1000,
	autoplay: true,
	arrows: false,
	autoplaySpeed: 4000,
	slidesToShow: 1,
	slidesToScroll: 1,
  vertical: true,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				speed: 1000,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				speed: 1000,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				speed: 1000,
			}
		},
	]
  }
  return (
	  <section className="breeder-gallery-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-0 order-lg-2">
              <Slider className="breeder-gallery-active" {...settings}>
                <div className="breeder-gallery-item">
                  <img src="img/about/About5.jpg" alt="" />
                </div>
                <div className="breeder-gallery-item">
				<img src="img/about/About6.jpg" alt="" />
                </div>
                <div className="breeder-gallery-item">
				<img src="img/about/About7.jpg" alt="" />
                </div>
                <div className="breeder-gallery-item">
				<img src="img/about/About8.jpg" alt="" />
                </div>
              </Slider>
            </div>
            <div className="col-lg-6">
              <div className="adoption-content">
                {/* <h5 className="sub-title">Sunshine Animal Clinic</h5> */}
                <h2 className="title">Our <span>Commitment</span></h2>
                <p>Our goal here at Sunshine Animal Clinic is to assemble a veterinary health care team committed to providing exceptional client service and veterinary health care for Jacksonville pets and other surrounding cities. The Sunshine Animal Clinic team displays an unrivaled commitment to our clients through continuing education, technological advances in veterinary medicine and service, and most importantly, administering compassionate care to all pets entrusted to us.</p>
                <div className="adoption-list">
                  <ul>
                    <li>Please contact us to arrange a preliminary appointment - we'd love to get to know you and your pet.</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AboutUs2;
