import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ServicesSidebar = () => {
  const location = useLocation();

  useEffect(() => {
    handleActive();
  }, [location]);

  const handleActive = () => {
    document.querySelectorAll('.breeder-details-area .submenu li').forEach(el => {
      el.classList.remove('active');
    });
    document.querySelectorAll('.breeder-details-area .submenu li a').forEach(a => {
      if (a.href.includes(location.pathname)) a.parentNode.className += ' active';
    });
  }

  return (
    <aside className="servicessidebar-sidebar">
      <div className="widget breeder-widget">
        <div className="servicessidebar-widget">
          <h4 className="fw-title animated animatedFadeInUp fadeInUp">Our Services</h4>
          <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
          <div className="Services-link">
            <ul className="submenu">
              <li><Link to="/services-wellness" onClick={(e)=> handleActive(e)}>Physical Wellness Exams</Link></li>
              <li><Link to="/services-emergency" onClick={(e)=> handleActive(e)}>Emergency & Critical Care</Link></li>
              <li><Link to="/services-vaccination" onClick={(e)=> handleActive(e)}>Vaccination</Link></li>
              <li><Link to="/services-spay-and-neuter" onClick={(e)=> handleActive(e)}>Spay / Neuter Surgery</Link></li>
              <li><Link to="/services-dental" onClick={(e)=> handleActive(e)}>Dental</Link></li>
              <li><Link to="/services-internal-medicine" onClick={(e)=> handleActive(e)}>Internal Medicine</Link></li>
              <li><Link to="/services-heartworm" onClick={(e)=> handleActive(e)}>Heartworm Prevention</Link></li>
              <li><Link to="/services-deworming" onClick={(e)=> handleActive(e)}>Pet Deworming</Link></li>
              <li><Link to="/services-skin-care" onClick={(e)=> handleActive(e)}>Pet Skin care</Link></li>
              <li><Link to="/services-boarding" onClick={(e)=> handleActive(e)}>Pet Boarding</Link></li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="widget sidebar-newsletter">
        <div className="sn-icon">
          <img src="img/icon/sn_icon.png" alt="" />
        </div>
        <div className="sn-title">
          <h4 className="title">Join our Family!</h4>
          <p>Get all the latest information on Events, Sales and Offers.</p>
        </div>

        <form  className="sn-form">
         <div className="row">
            <div className="col-6">
             <input type="text" placeholder="First Name" />
            </div>
            <div className="col-6">
             <input type="text" placeholder="Last Name" />
            </div>
          </div>
          <input type="text" placeholder="Enter Your Email" />
          <button className="btn">subscribe</button>
        </form>
      </div> */}
    </aside>
  );
};

export default ServicesSidebar;
