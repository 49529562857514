import React from 'react';
import Slider from 'react-slick'
import {Link} from 'react-router-dom'



function PrevArrow(props){
  const {className,onClick} = props;
  return(
    <button type='button' className ={ className } onClick={ onClick }><img src="img/icon/arrow.png" alt='prev' /></button>
  );
}
function NextArrow(props){
  const {className,onClick} = props;
  return(
    <button type='button' className ={ className } onClick={ onClick }><img src="img/icon/arrow.png" alt='next' /></button>
  );
}

function ServiceList() {
  const settings = {

  dots: false,
	infinite: true,
	speed: 1000,
	autoplay: false,
	arrows: true,
	autoplaySpeed: 3000,
	prevArrow: <PrevArrow/>,
	nextArrow: <NextArrow/>,
	slidesToShow: 4,
	slidesToScroll: 4,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 2,
				infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				speed: 1000,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				arrows: true,
				speed: 1000,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				speed: 1000,
			}
		},
	]
  }
  return (



	  <section className="adoption-area-two pt-110 pb-110" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65" >
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Services We Offer</h5>
                <h2 className="title">Because We Care</h2>
                <p>We'll treat your pets like they're our own.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container custom-container">
          <Slider className="row adopt-active" {...settings}>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-vet-with-cat"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-wellness" className="title">Physical Wellness Exam</Link></h3>
                  <p>Our wellness programs are designed to prevent disease and prolong the life of your pet.</p>
                  <Link to="/services-wellness" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-dog-with-first-aid-kit-bag"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-emergency" className="title">Emergency &amp; Critical Care</Link></h3>
                  <p>Critical situations require specialized care.</p>
                  <Link to="/services-emergency" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-syringe"></i>
                </div>
                <div className="adopt-content">
                  <h3 ><Link to="/services-vaccination" className="title">Vaccination</Link></h3>
                  <p>The secret to a long, healthy life for your pet is to prevent diseases before they can take hold of them.</p>
                  <Link to="/services-vaccination" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-pet-hotel-sign-with-a-dog-and-a-cat-under-a-roof-line"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-spay-and-neuter" className="title">Spay / Neuter Surgery</Link></h3>
                  <p>Spaying and neutering a dog or cat are an important part of pet population control while also benefiting you and your pet.</p>
                  <Link to="/services-spay-and-neuter" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-dog-bone"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-dental" className="title">Dental</Link></h3>
                  <p>Dental health problems are common and if left untreated can progress into painful and serious systemic conditions.</p>
                  <Link to="/services-dental" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-veterinarian-hospital"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-internal-medicine" className="title">Internal Medicine</Link></h3>
                  <p>Veterinary internal medicine provides treatment for issues involving your pet's internal systems.</p>
                  <Link to="/services-internal-medicine" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-dog-17"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-heartworm" className="title">Heartworm Prevention</Link></h3>
                  <p> Heartworm prevention is an important part of providing essential care to your pet.</p>
                  <Link to="/services-heartworm" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-animal-1"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-deworming" className="title">Pet Deworming</Link></h3>
                  <p>Regularly deworming your pet helps keep their digestive system happy and healthy.</p>
                  <Link to="/services-deworming" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-animals-2"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-skin-care" className="title">Pet Skincare</Link></h3>
                  <p>Skin diseases are among the more common illnesses in the pet population and can be difficult to diagnose and treat.</p>
                  <Link to="/services-skin-care" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-xl">
              <div className="adoption-item">
                <div className="adopt-thumb">
                <i className="flaticon-pet-carrier"></i>
                </div>
                <div className="adopt-content">
                  <h3><Link to="/services-boarding" className="title">Pet Boarding</Link></h3>
                  <p>If you are going away and you are concerned about your pet receiving proper monitoring and/or medicating, our veterinarian...</p>
                  <Link to="/services-boarding" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
  )
}

export default ServiceList;
