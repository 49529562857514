import React from 'react';

import Deworming from '../components/serviceDetails/Deworming';
import DewormingBreadCrumb from '../components/serviceDetails/DewormingBreadCrumb';
import DewormingFAQ from '../components/serviceDetails/DewormingFAQ';
// import BlogsHome from '../components/BlogsHome'
import Newsletter from '../components/NewsLetter';

function ServicesDeworming() {
  return (
	  <main>
		<DewormingBreadCrumb/>
		<Deworming/>
		<DewormingFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesDeworming;
