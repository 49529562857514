/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'

function PricingCard(props) {
  return (
    <div className="col-lg-4 col-md-6 pricing-container">
        <div className="pricing-card">
          <div className="title pricing-card-title"><h5 className="pricing-card-font">{props.title}</h5></div>
          <div className="pricing-card-price">
            <ul>
            <h2 className="pricing-card-font"><span>$</span>{props.price}</h2>
            <label>{props.label1}</label>
            <label>{props.label2}</label>
            <label>{props.label3}</label>
            <label>{props.label4}</label>
            <label>{props.label5}</label>
            <label>{props.label6}</label>
            <label>{props.label7}</label>
            </ul>
          </div>
          <div className="pricing-card-bottom">
            <h5 className="pricing-card-availability pricing-card-font"><span>Open </span>{props.availability}</h5>
              <Link to="/book-an-appointment">
              <li className="btn rounded-btn pricing-card-font pricing-card-btn">
              Book An Appointment
              </li>
              </Link>
          </div>
        </div>
    </div>
  )
}

export default PricingCard;
