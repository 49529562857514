import React from 'react';

import Boarding from '../components/serviceDetails/Boarding';
import BoardingPricing from '../components/serviceDetails/BoardingPricing'
import BoardingBreadCrumb from '../components/serviceDetails/BoardingBreadCrumb';
import BoardingFAQ from '../components/serviceDetails/BoardingFAQ';
// import BlogsHome from '../components/BlogsHome';

function ServicesBoarding() {
  return (
	  <main>
		<BoardingBreadCrumb/>
		<Boarding/>
		<BoardingPricing/>
		<BoardingFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
	  </main>
  )
}

export default ServicesBoarding;
