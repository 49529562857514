import React from 'react';
import ServicesSidebar from './ServicesSidebar';
import Accordion from 'react-bootstrap/Accordion';

function InternalMedicine() {
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title animated animatedFadeInUp fadeInUp">
                Veterinary Internal Medicine
              </h4>
              <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                Sunshine Animal Clinic
              </h6>
              <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                {' '}
              </span>
              <div className="services-detail-content">
                <p>
                  Internal system problems in your pet are treated by veterinary
                  internal medicine. Our team of veterinarians have years of
                  experience diagnosing and treating challenging illnesses,
                  ranging from heart and lung conditions to problems of the
                  brain and neurological system.
                </p>
                <p>
                  At Sunshine Animal Clinic, we have a variety of diagnostic
                  resources and therapeutic choices available. We have the
                  ability to care for pets with a variety of illnesses while
                  providing flexible treatment options for those bothersome
                  patients who don't react to standard medicine.
                </p>
                <br></br>
                <h4 className="title animated animatedFadeInUp fadeInUp">
                  Internal Medicine Conditions
                </h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">
                  What you need to know
                </h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp">
                  {' '}
                </span>
                <p>
                  If your pet is displaying signs of an internal condition, our
                  veterinarians will perform a thorough physical examination of
                  your pet to identify the problem and develop a thorough
                  treatment strategy according to their requirements.
                </p>
                <p>
                  The following conditions are diagnosed and treated by our
                  veterinarians:
                </p>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      Cardiology (heart-related disorders)
                    </Accordion.Header>
                    <Accordion.Body>
                      Dogs and cats can get cardiac conditions and diseases just
                      like people can. Our veterinarians can examine your pet
                      for indications of heart issues using the equipment in our
                      on-site vet lab, giving you answers immediately. Early
                      therapy means quicker outcomes.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header> Reproductive Issues</Accordion.Header>
                    <Accordion.Body>
                      At Sunshine Animal Clinic, our staff of veterinary experts
                      are aware of the financial commitment and time commitment
                      necessary for healthy companion animal breeding. Our
                      veterinarians will work to identify the cause and provide
                      suitable treatment choices if your animal is having
                      reproductive issues.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {' '}
                      Immunology (immune system disorders)
                    </Accordion.Header>
                    <Accordion.Body>
                      For many different illnesses and infections, a robust and
                      functional immune system is required. The immune system in
                      healthy dogs and cats is in charge of identifying foreign
                      cells like bacteria or fungi and then eliminating them
                      from the body.
                      <br></br>
                      <br></br>
                      But in some illnesses, the immune system reacts
                      incorrectly and can start attacking the animal's own
                      cells. Immune-mediated disorder refers to the collective
                      term for several illnesses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {' '}
                      Pulmonary Disorders (diseases of the lungs)
                    </Accordion.Header>
                    <Accordion.Body>
                      Animals can also suffer from a variety of breathing
                      disorders and respiratory diseases, making them
                      susceptible to the same respiratory conditions as humans.
                      <br></br>
                      <br></br>
                      These respiratory problems can be troubling to pet owners,
                      so it is important to be knowledgeable and understand how
                      to spot symptoms of respiratory illness, how to treat it
                      or manage it, and how to prevent it.
                      <br></br>
                      <br></br>
                      Canine distemper virus, pneumonia, lung tumors, and
                      chronic obstructive pulmonary disease (COPD) are a few of
                      the prevalent pulmonary conditions that affect dogs.
                      <br></br>
                      <br></br>
                      Asthma, bronchitis, parasitic worms, bacterial infections,
                      and viruses are a few of the more typical respiratory
                      conditions that affect cats.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {' '}
                      Gastroenterology (digestive organs)
                    </Accordion.Header>
                    <Accordion.Body>
                      The system of organs in multicellular animals that
                      receives food, breaks it down to release energy and
                      nutrients, and then excretes the leftover waste is known
                      as the gastrointestinal tract, sometimes known as the GI
                      tract, the alimentary canal, or the gut.
                      <br></br>
                      <br></br>
                      GI symptoms in pets include blood in the stool,
                      constipation, diarrhea, vomiting, gas, abdominal pain,
                      loss of appetite, and weight loss.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      {' '}
                      Oncology (tumors and cancer)
                    </Accordion.Header>
                    <Accordion.Body>
                      We hope that no one, not even our four-legged companions,
                      has to deal with cancer. Fortunately, improvements in
                      treatment options and associated advantages have resulted
                      from learnings in both humans and animals over time. New
                      options are being investigated, and veterinarians are
                      providing additional therapy that their patients are open
                      to pursuing.
                      <br></br>
                      <br></br>
                      The more common types of cancer that cause death in dogs
                      include lymphoma, bone cancer, skin cancer, splenic or
                      liver tumor, and bladder cancer, among others. The most
                      common tumors in cats include lymphoma, oral tumor,
                      mammary tumors, and other types.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      {' '}
                      Urology (urinary functions, genitourinary tract)
                    </Accordion.Header>
                    <Accordion.Body>
                      Urology disorders such urinary tract infections (UTIs) in
                      dogs and feline lower urinary tract disease (FLUTD) in
                      cats are also covered by veterinary internal medicine.
                      <br></br>
                      <br></br>
                      The kidneys, the tubes that take urine to the bladder, the
                      bladder, and the urethra, which carries pee out of your
                      pet's body, make up their urinary tract. Any component of
                      this system can be impacted by urinary tract diseases.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      {' '}
                      Endocrinology (glands and their functions)
                    </Accordion.Header>
                    <Accordion.Body>
                      The endocrine system is a group of glands that produce
                      various hormones into the bloodstream to control body
                      functions. The endocrine organs are small yet being quite
                      powerful. Skin issues and an unruly coat are signs that
                      your pet's endocrine system isn't functioning properly.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      {' '}
                      Neurology (brain and nervous system disorders)
                    </Accordion.Header>
                    <Accordion.Body>
                      It's crucial for pet parents to be aware of the symptoms
                      because neurological problems can afflict animals of any
                      age or breed.
                      <br></br>
                      <br></br>
                      The diagnosis and treatment of diseases of the central
                      nervous system in animals are the focus of the specialist
                      field of veterinary medicine known as neurology.
                      Conditions and disorders known as neurological problems
                      prevent or adversely influence your pet's central nervous
                      system's ability to connect with various sections of their
                      body.
                      <br></br>
                      <br></br>
                      <p>
                        There are a number of neurological conditions that can
                        affect cats and dogs including:
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Intervertebral disc
                        disease (IVDD)
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Canine Degenerative
                        Myelopathy
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Stroke
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Meningitis
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Encephalitis
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Epilepsy (seizures)
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Cancer
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Degenerative disorders{' '}
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Spinal or brain trauma
                      </p>
                      <p className="tab">
                        <i className="flaticon-tick" /> Peripheral nerve
                        diseases
                      </p>
                      <p>
                        If your furry pet requires diagnoses or care that are
                        beyond the scope of our experienced veterinarians, we
                        will be happy to refer you to an animal internal
                        medicine specialist near Jacksonville. Our vets will
                        work closely with your specialist to ensure that your
                        pet receives the best treatment possible.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default InternalMedicine;
