import React from 'react';

import SpayNeuter from '../components/serviceDetails/SpayNeuter'
import SpayNeuterPricing from '../components/serviceDetails/SpayNeuterPricing'
import SpayNeuterBreadCumb from '../components/serviceDetails/SpayNeuterBreadCumb'
import SpayNeuterFAQ from '../components/serviceDetails/SpayNeuterFAQ'
// import BlogsHome from '../components/BlogsHome'
import Newsletter from '../components/NewsLetter'

function ServicesVaccination() {
  return (
	  <main>
		<SpayNeuterBreadCumb/>
		<SpayNeuter/>
		<SpayNeuterPricing/>
		<SpayNeuterFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesVaccination;
