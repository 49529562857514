/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'
import PricingCard from './PricingCard'

function BoardingPricing() {

  return (
    <section className="pricing-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Price List</h5>
                <h2 className="title">Dog Boarding Prices</h2>

              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="One Dog" price="40" label1="Per Night" label2="*Prices may vary for Weekends / Holidays" availability="Monday - Saturday"/> 
            <PricingCard title="one dog - w/ medications" price="50" label1="Per Night" label2="*Prices may vary for Weekends / Holidays" availability="Monday - Saturday"/>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <h2 className="title">Cat Boarding Prices</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="one cat" price="30" label1="Per Night" label2="*Prices may vary for Weekends / Holidays" availability="Monday - Saturday"/>
            <PricingCard title="one cat - w/ medications" price="40" label1="Per Night" label2="*Prices may vary for Weekends / Holidays" availability="Monday - Saturday"/>
          </div>
        </div>
    </section>
  )
}

export default BoardingPricing;
